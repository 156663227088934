﻿/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
	background: #fff;
	padding: 0;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;

	.f_1 {
		border-top: 1px solid #eaeaea;
		@include rem(padding-top, 50px);
	}

	.f_2 .inner {
		border-top: 1px solid #C7C7C7;
		display: flex;
		justify-content: center;
		@include rem(padding, 30px 0);
	}

	.logo-foot {
		display: block;
		@include rem(margin, 0 auto 50px);
	}

	h3 {
		@include rem(font-size, 16px);
		font-weight: 600;
		line-height: 1.3em;
		color: #7d7b79;
		text-transform: uppercase;
		@include rem(margin-bottom, 20px);
	}

	ul {
		@include rem(margin, 0 0 40px 0);
		&.unstyled {
			li {
				padding: 0;

				&:after {
					content: none;
				}
			}
		}
		
		&.contact-list {
			li {
				@include rem(padding, 0 0 0 30px);

				.icon {
					position: absolute;
					left: 0;
					top: 0;
					color: #7a6e60;
				}

				&:after {
					content: none;
				}
			}
		}
	}
	
	a {
		color: rgba(125,123,121,.83);
		text-decoration: none;

		&:hover {
			color: rgba(125,123,121,1);
			text-decoration: underline;
		}
	}	
	
	.social-nav {
		ul {
			li {
				padding: 0 5px;

				&:after {
					content: none;
				}

				a {
					border-color: #7a6e60;
					color: #7a6e60;

					&:hover {
						background: #7a6e60;
						color: #fff;
					}
				}
			}
		}
	}
}

.orwin {
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
	transition: all 0.3s;
	opacity: .5;

	&:hover {
		filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
		-webkit-filter: grayscale(0%);
		opacity: 1;
	}

	.text {
		position: absolute;
		left: 0;
		top: 0;
		text-indent: -80000px;
		display: block;
	}
}