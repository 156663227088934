@charset "UTF-8";
/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1130px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */
/**
 * Colors
 */
/**
 * Font
 */
/**
/* Font weight variables
 */
@font-face {
  font-family: 'space_groteskregular';
  src: url("../font/spacegrotesk-regular-webfont.woff2") format("woff2"), url("../font/spacegrotesk-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_groteskmedium';
  src: url("../font/spacegrotesk-medium-webfont.woff2") format("woff2"), url("../font/spacegrotesk-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_grotesksemibold';
  src: url("../font/spacegrotesk-semibold-webfont.woff2") format("woff2"), url("../font/spacegrotesk-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_groteskbold';
  src: url("../font/spacegrotesk-bold-webfont.woff2") format("woff2"), url("../font/spacegrotesk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/**
 * Font family
 */
/**
 * Border radius
 */
/**
 * Scale
 */
/**
 * Translate
 */
/**
 * Skew
 */
/**
 * Rotate
 */
/**
 * Transition
 */
/**
 * REM units
 */
body.fancybox-active {
  overflow: hidden;
}

body.fancybox-iosfix {
  position: fixed;
  left: 0;
  right: 0;
}

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99992;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption-wrap, .fancybox-infobar, .fancybox-toolbar {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s,visibility 0s linear .25s;
  box-sizing: border-box;
}

.fancybox-show-caption .fancybox-caption-wrap, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s,visibility 0s;
}

.fancybox-infobar {
  top: 0;
  left: 0;
  font-size: 13px;
  padding: 0 10px;
  height: 44px;
  min-width: 44px;
  line-height: 44px;
  color: #ccc;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
  mix-blend-mode: exclusion;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translateZ(0);
}

.fancybox-is-closing .fancybox-stage {
  overflow: visible;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--image {
  overflow: visible;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--video .fancybox-content, .fancybox-slide--video iframe {
  background: #000;
}

.fancybox-slide--map .fancybox-content, .fancybox-slide--map iframe {
  background: #e5e3df;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide > base, .fancybox-slide > link, .fancybox-slide > meta, .fancybox-slide > script, .fancybox-slide > style, .fancybox-slide > title {
  display: none;
}

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-image, .fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff;
}

.fancybox-iframe {
  display: block;
  padding: 0;
  border: 0;
  height: 100%;
}

.fancybox-error, .fancybox-iframe {
  margin: 0;
  width: 100%;
  background: #fff;
}

.fancybox-error {
  padding: 40px;
  max-width: 380px;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 16px;
  line-height: 20px;
}

.fancybox-button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 10px;
  border: 0;
  border-radius: 0;
  background: rgba(30, 30, 30, 0.6);
  transition: color .3s ease;
  cursor: pointer;
  outline: none;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:focus, .fancybox-button:hover {
  color: #fff;
}

.fancybox-button[disabled] {
  color: #ccc;
  cursor: default;
  opacity: .6;
}

.fancybox-button svg {
  display: block;
  position: relative;
  overflow: visible;
  shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3;
}

.fancybox-button--share svg path {
  stroke-width: 1;
}

.fancybox-button--pause svg path:nth-child(1), .fancybox-button--play svg path:nth-child(2) {
  display: none;
}

.fancybox-button--zoom svg path {
  fill: transparent;
}

.fancybox-navigation {
  display: none;
}

.fancybox-show-nav .fancybox-navigation {
  display: block;
}

.fancybox-navigation button {
  position: absolute;
  top: 50%;
  margin: -50px 0 0;
  z-index: 99997;
  background: transparent;
  width: 60px;
  height: 100px;
  padding: 17px;
}

.fancybox-navigation button:before {
  content: "";
  position: absolute;
  top: 30px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 0;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.fancybox-close-small:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 22px/30px Arial,Helvetica Neue,Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  transition: background-color .25s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus {
  outline: none;
}

.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}

.fancybox-slide--iframe .fancybox-close-small, .fancybox-slide--image .fancybox-close-small {
  top: 0;
  right: -40px;
}

.fancybox-slide--iframe .fancybox-close-small:after, .fancybox-slide--image .fancybox-close-small:after {
  font-size: 35px;
  color: #aaa;
}

.fancybox-slide--iframe .fancybox-close-small:hover:after, .fancybox-slide--image .fancybox-close-small:hover:after {
  color: #fff;
  background: transparent;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none;
}

.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 2vw 0;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8));
  pointer-events: none;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a, .fancybox-caption button, .fancybox-caption select {
  pointer-events: all;
  position: relative;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

.fancybox-slide > .fancybox-loading {
  border: 6px solid rgba(99, 99, 99, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}

.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  opacity: 1;
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

.fancybox-share {
  padding: 30px;
  border-radius: 3px;
  background: #f4f4f4;
  max-width: 90%;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px;
  font-size: 35px;
  font-weight: 700;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

p.fancybox-share__links {
  margin-right: -10px;
}

.fancybox-share__button {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px 10px 0;
  padding: 0 15px;
  min-width: 130px;
  border: 0;
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  transition: all .2s;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  position: relative;
  top: -1px;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  vertical-align: middle;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 0;
  padding: 10px 15px;
  background: transparent;
  color: #5d5b5b;
  font-size: 14px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #d7d7d7;
}

.fancybox-thumbs {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 212px;
  margin: 0;
  padding: 2px 2px 4px;
  background: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
  white-space: nowrap;
}

.fancybox-thumbs-x > ul {
  overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 2px;
  width: 100px;
  height: 75px;
  max-width: calc(50% - 4px);
  max-height: calc(100% - 8px);
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px);
  }
}

/* qTip2 v2.2.0 None | qtip2.com | Licensed MIT, GPL | Fri Nov 29 2013 10:36:05 */
.qtip {
  position: absolute;
  left: -28000px;
  top: -28000px;
  display: none;
  max-width: 280px;
  min-width: 50px;
  font-size: 10.5px;
  line-height: 12px;
  direction: ltr;
  box-shadow: none;
  padding: 0;
}

.qtip-content {
  position: relative;
  padding: 5px 9px;
  overflow: hidden;
  text-align: left;
  word-wrap: break-word;
}

.qtip-titlebar {
  position: relative;
  padding: 5px 35px 5px 10px;
  overflow: hidden;
  border-width: 0 0 1px;
  font-weight: 700;
}

.qtip-titlebar + .qtip-content {
  border-top-width: 0 !important;
}

.qtip-close {
  position: absolute;
  right: -9px;
  top: -9px;
  cursor: pointer;
  outline: medium none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.qtip-titlebar .qtip-close {
  right: 4px;
  top: 50%;
  margin-top: -9px;
}

* html .qtip-titlebar .qtip-close {
  top: 16px;
}

.qtip-titlebar .ui-icon, .qtip-icon .ui-icon {
  display: block;
  text-indent: -1000em;
  direction: ltr;
}

.qtip-icon, .qtip-icon .ui-icon {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
}

.qtip-icon .ui-icon {
  width: 18px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  text-indent: 0;
  font: 400 bold 10px/13px Tahoma,sans-serif;
  color: inherit;
  background: transparent none no-repeat -100em -100em;
}

.qtip-default {
  border-width: 1px;
  border-style: solid;
  border-color: #F1D031;
  background-color: #FFFFA3;
  color: #555;
}

.qtip-default .qtip-titlebar {
  background-color: #FFEF93;
}

.qtip-default .qtip-icon {
  border-color: #CCC;
  background: #F1F1F1;
  color: #777;
}

.qtip-default .qtip-titlebar .qtip-close {
  border-color: #AAA;
  color: #111;
}

.qtip .qtip-tip {
  margin: 0 auto;
  overflow: hidden;
  z-index: 10;
}

x:-o-prefocus, .qtip .qtip-tip {
  visibility: hidden;
}

.qtip .qtip-tip, .qtip .qtip-tip .qtip-vml, .qtip .qtip-tip canvas {
  position: absolute;
  color: #123456;
  background: transparent;
  border: 0 dashed transparent;
}

.qtip .qtip-tip canvas {
  top: 0;
  left: 0;
}

.qtip .qtip-tip .qtip-vml {
  behavior: url(#default#VML);
  display: inline-block;
  visibility: visible;
}

/*
 * easy-autocomplete
 * jQuery plugin for autocompletion
 * 
 * @author Łukasz Pawełczak (http://github.com/pawelczak)
 * @version 1.3.5
 * Copyright  License: 
 */
.easy-autocomplete {
  position: relative;
}

.easy-autocomplete input {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 12px;
}

.easy-autocomplete input:hover, .easy-autocomplete input:focus {
  box-shadow: none;
}

.easy-autocomplete a {
  display: block;
}

.easy-autocomplete.eac-blue-light input:hover, .easy-autocomplete.eac-blue-light input:focus {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

.easy-autocomplete.eac-blue-light ul {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

.easy-autocomplete.eac-blue-light ul li, .easy-autocomplete.eac-blue-light ul .eac-category {
  border-color: #66afe9;
}

.easy-autocomplete.eac-blue-light ul li.selected, .easy-autocomplete.eac-blue-light ul .eac-category.selected {
  background-color: #ecf5fc;
}

.easy-autocomplete.eac-green-light input:hover, .easy-autocomplete.eac-green-light input:focus {
  border-color: #41DB00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(146, 237, 107, 0.6);
}

.easy-autocomplete.eac-green-light ul {
  border-color: #41DB00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(146, 237, 107, 0.6);
}

.easy-autocomplete.eac-green-light ul li, .easy-autocomplete.eac-green-light ul .eac-category {
  border-color: #41DB00;
}

.easy-autocomplete.eac-green-light ul li.selected, .easy-autocomplete.eac-green-light ul .eac-category.selected {
  background-color: #9eff75;
}

.easy-autocomplete.eac-red-light input:hover, .easy-autocomplete.eac-red-light input:focus {
  border-color: #ff5b5b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 90, 0.6);
}

.easy-autocomplete.eac-red-light ul {
  border-color: #ff5b5b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 90, 0.6);
}

.easy-autocomplete.eac-red-light ul li, .easy-autocomplete.eac-red-light ul .eac-category {
  border-color: #ff5b5b;
}

.easy-autocomplete.eac-red-light ul li.selected, .easy-autocomplete.eac-red-light ul .eac-category.selected {
  background-color: #ff8e8e;
}

.easy-autocomplete.eac-yellow-light input:hover, .easy-autocomplete.eac-yellow-light input:focus {
  border-color: #ffdb00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 231, 84, 0.6);
}

.easy-autocomplete.eac-yellow-light ul {
  border-color: #ffdb00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 231, 84, 0.6);
}

.easy-autocomplete.eac-yellow-light ul li, .easy-autocomplete.eac-yellow-light ul .eac-category {
  border-color: #ffdb00;
}

.easy-autocomplete.eac-yellow-light ul li.selected, .easy-autocomplete.eac-yellow-light ul .eac-category.selected {
  background-color: #ffe233;
}

.easy-autocomplete.eac-dark-light input:hover, .easy-autocomplete.eac-dark-light input:focus {
  border-color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(55, 55, 55, 0.6);
}

.easy-autocomplete.eac-dark-light ul {
  border-color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(55, 55, 55, 0.6);
}

.easy-autocomplete.eac-dark-light ul li, .easy-autocomplete.eac-dark-light ul .eac-category {
  border-color: #333;
}

.easy-autocomplete.eac-dark-light ul li.selected, .easy-autocomplete.eac-dark-light ul .eac-category.selected {
  background-color: #4d4d4d;
  color: #fff;
}

.easy-autocomplete.eac-dark {
  color: #fff;
}

.easy-autocomplete.eac-dark input {
  background-color: #404040;
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6;
}

.easy-autocomplete.eac-dark input:hover, .easy-autocomplete.eac-dark input:focus {
  border-color: #333;
  box-shadow: 0;
}

.easy-autocomplete.eac-dark ul {
  border-color: #333;
}

.easy-autocomplete.eac-dark ul li, .easy-autocomplete.eac-dark ul .eac-category {
  background-color: #404040;
  border-color: #333;
}

.easy-autocomplete.eac-dark ul li.selected, .easy-autocomplete.eac-dark ul .eac-category.selected {
  background-color: #737373;
  color: #f6f6f6;
}

.easy-autocomplete.eac-dark-glass {
  color: #fff;
}

.easy-autocomplete.eac-dark-glass input {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6;
}

.easy-autocomplete.eac-dark-glass input:hover, .easy-autocomplete.eac-dark-glass input:focus {
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0;
}

.easy-autocomplete.eac-dark-glass ul {
  border-color: rgba(0, 0, 0, 0.8);
}

.easy-autocomplete.eac-dark-glass ul li, .easy-autocomplete.eac-dark-glass ul .eac-category {
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
}

.easy-autocomplete.eac-dark-glass ul li.selected, .easy-autocomplete.eac-dark-glass ul .eac-category.selected {
  background-color: rgba(64, 64, 64, 0.8);
  color: #f6f6f6;
}

.easy-autocomplete.eac-dark-glass ul li:last-child, .easy-autocomplete.eac-dark-glass ul .eac-category:last-child {
  border-radius: 0 0 4px 4px;
}

.easy-autocomplete.eac-blue {
  color: #fff;
}

.easy-autocomplete.eac-blue input {
  background-color: #6d9ed1;
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6;
}

.easy-autocomplete.eac-blue input::-webkit-input-placeholder {
  color: #f6f6f6;
}

.easy-autocomplete.eac-blue input:-moz-placeholder {
  color: #f6f6f6;
}

.easy-autocomplete.eac-blue input::-moz-placeholder {
  color: #f6f6f6;
}

.easy-autocomplete.eac-blue input:-ms-input-placeholder {
  color: #f6f6f6;
}

.easy-autocomplete.eac-blue input:hover, .easy-autocomplete.eac-blue input:focus {
  border-color: #5A91CB;
  box-shadow: 0;
}

.easy-autocomplete.eac-blue ul {
  border-color: #5A91CB;
}

.easy-autocomplete.eac-blue ul li, .easy-autocomplete.eac-blue ul .eac-category {
  background-color: #6d9ed1;
  border-color: #5A91CB;
}

.easy-autocomplete.eac-blue ul li.selected, .easy-autocomplete.eac-blue ul .eac-category.selected {
  background-color: #94b8dd;
  color: #f6f6f6;
}

.easy-autocomplete.eac-yellow {
  color: #333;
}

.easy-autocomplete.eac-yellow input {
  background-color: #ffdb7e;
  border-color: #333;
  border-radius: 4px;
  box-shadow: 0;
  color: #333;
}

.easy-autocomplete.eac-yellow input:hover, .easy-autocomplete.eac-yellow input:focus {
  border-color: #333;
  box-shadow: 0;
}

.easy-autocomplete.eac-yellow ul {
  border-color: #333;
}

.easy-autocomplete.eac-yellow ul li, .easy-autocomplete.eac-yellow ul .eac-category {
  background-color: #ffdb7e;
  border-color: #333;
}

.easy-autocomplete.eac-yellow ul li.selected, .easy-autocomplete.eac-yellow ul .eac-category.selected {
  background-color: #ffe9b1;
  color: #333;
}

.easy-autocomplete.eac-purple {
  color: #333;
}

.easy-autocomplete.eac-purple input {
  background-color: #d6d1e7;
  border-color: #b8afd5;
  box-shadow: 0;
  color: #333;
}

.easy-autocomplete.eac-purple input:hover, .easy-autocomplete.eac-purple input:focus {
  border-color: #333;
  box-shadow: 0;
}

.easy-autocomplete.eac-purple ul {
  border-color: #333;
}

.easy-autocomplete.eac-purple ul li, .easy-autocomplete.eac-purple ul .eac-category {
  background-color: #d6d1e7;
  border-color: #333;
}

.easy-autocomplete.eac-purple ul li.selected, .easy-autocomplete.eac-purple ul .eac-category.selected {
  background-color: #ebe8f3;
  color: #333;
}

.easy-autocomplete.eac-bootstrap input {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #555;
  padding: 6px 12px;
}

.easy-autocomplete-container {
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.easy-autocomplete-container ul {
  background: none repeat scroll 0 0 #ffffff;
  border-top: 1px dotted #ccc;
  display: none;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  position: relative;
  top: -1px;
}

.easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category {
  background: inherit;
  border-color: #ccc;
  border-image: none;
  border-style: solid;
  border-width: 0 1px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 12px;
}

.easy-autocomplete-container ul li:last-child {
  border-radius: 0 0 2px 2px;
  border-width: 0 1px 1px;
}

.easy-autocomplete-container ul li.selected {
  background: none repeat scroll 0 0 #ebebeb;
  cursor: pointer;
}

.easy-autocomplete-container ul li.selected div {
  font-weight: normal;
}

.easy-autocomplete-container ul li div {
  display: block;
  font-weight: normal;
  word-break: break-all;
}

.easy-autocomplete-container ul li b {
  font-weight: bold;
}

.easy-autocomplete-container ul .eac-category {
  font-color: #aaa;
  font-style: italic;
}

.eac-description .eac-item span {
  color: #aaa;
  font-style: italic;
  font-size: 0.9em;
}

.eac-icon-left .eac-item img {
  margin-right: 4px;
  max-height: 30px;
}

.eac-icon-right .eac-item {
  margin-top: 8px;
  min-height: 24px;
  position: relative;
}

.eac-icon-right .eac-item img {
  margin-left: 4px;
  max-height: 30px;
  position: absolute;
  right: -4px;
  top: -8px;
}

/*# sourceMappingURL=easy-autocomplete.min.css.map */
/*
 *
 * OBJEVUJ PAMÁTKY
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */
/************************************************************************************
RESET
*************************************************************************************/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
img {
  display: block;
}

input[type='radio'] {
  vertical-align: text-bottom;
}

input[type='checkbox'] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

label,
input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
  overflow: visible;
}

em,
i {
  font-weight: inherit;
}

/************************************************************************************
GENERAL
*************************************************************************************/
html {
  height: 100%;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.625em;
  color: #7d7b79;
  background: #efeeec;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

/* Box sizing */
*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* Anchors */
a {
  color: #7a6e60;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

/* Heading */
h1,
.h1 {
  font-family: Noto serif, serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 1.875rem 0;
}

h1.small,
.h1.small {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-family: Noto serif, serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 1.25rem 0;
}

h2.small,
.h2.small {
  font-size: 1.5rem;
}

h3,
.h3 {
  font-family: Noto serif, serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 0.625rem 0;
}

h3.small,
.h3.small {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-family: Noto serif, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 0.625rem 0;
}

h5,
.h5 {
  font-family: Noto serif, serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 0.625rem 0;
}

h6,
.h6 {
  font-family: Noto serif, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  margin: 0 0 0.625rem 0;
}

/* Paragraphs */
p {
  margin: 0 0 1.25rem 0;
}

address {
  font-style: normal;
}

cite {
  font-style: normal;
}

/* Lists */
ul,
ol {
  list-style: none;
  margin: 0 0 1.25rem 0;
}

ul li {
  padding: 0 0 0 1.25rem;
  margin: 0 0 0.3125rem 0;
}

ul li,
.footer ul:not(.unstyled) li {
  padding: 0 0 0 2.5rem;
  margin: 0 0 0.625rem 0;
  position: relative;
}

ul li:before,
.footer ul:not(.unstyled) li:before {
  content: '';
  width: 1.5625rem;
  height: 2px;
  background: #ADA295;
  position: absolute;
  top: 0.75rem;
  left: 0;
}

.footer ul:not(.unstyled) li:before {
  height: 1px;
}

ul.unstyled li {
  padding: 0;
  background: none;
}

ol {
  counter-reset: counter;
  margin-left: 0;
  *margin-left: 54px;
}

ol li {
  margin: 0 0 10px 0;
  padding: 0;
  padding: 0 0 0 2.5rem;
  *list-style-type: decimal;
  counter-increment: counter;
  position: relative;
}

ol li:before {
  color: #ADA295;
  content: counter(counter) ". ";
  position: absolute;
  left: 0;
  top: -0.125rem;
  font-size: 1.25rem;
  font-family: Noto serif, serif;
  font-weight: bold;
}

dl {
  margin: 0 0 20px 0;
}

dt {
  font-weight: bold;
}

/* Forms */
form {
  display: block;
}

fieldset {
  border: none;
}

label {
  font-weight: bold;
}

input,
select,
button {
  vertical-align: top;
}

/* Various */
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

p.small {
  font-size: 70%;
}

p.big {
  font-size: 130%;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: optimizeSpeed;
  /*                     */
  image-rendering: -moz-crisp-edges;
  /* Firefox             */
  image-rendering: -o-crisp-edges;
  /* Opera               */
  image-rendering: -webkit-optimize-contrast;
  /* Chrome (and Safari) */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+                */
}

img .alignnone {
  margin-bottom: 20px;
}

img .alignleft {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #d5d5d6;
  position: relative;
  top: 7px;
}

img .alignright {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
  border: 1px solid #d5d5d6;
  position: relative;
  top: 7px;
}

img .aligncenter {
  margin: 0 auto 20px auto;
  padding-top: 10px;
}

img .border {
  border: 1px solid #d5d5d6;
}

.bold {
  font-weight: bold;
}

.nowrap,
.nobr {
  white-space: nowrap !important;
}

/* Alignment */
.v-top {
  vertical-align: top;
}

.v-middle {
  vertical-align: middle;
}

.v-bottom {
  vertical-align: bottom;
}

.l {
  float: left;
}

.r {
  float: right;
}

.a-l {
  text-align: left;
}

.a-r {
  text-align: right;
}

.a-c {
  text-align: center;
}

/* Content */
.content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0px 0 30px #ada29580;
}

/* Block */
.block {
  padding: 3.75rem 0;
}

.block.narrow {
  padding: 1.875rem 0;
}

.block.bb {
  border-bottom: 1px solid #eaeaea;
}

.block.alt {
  background: #f9f8f6;
}

.block.alt-2 {
  background: #42403d;
  color: #fff;
}

.block.alt-2 h2 {
  color: #fff;
}

.block.alt-2 .section-head.alt h2 {
  color: #fff;
}

.block.alt-2 .btn:hover {
  border-color: #fff;
}

.block.alpha {
  padding-top: 0;
}

.block.omega {
  padding-bottom: 0;
}

.block.bg-white {
  background-color: white;
}

/* Section head */
.section-head {
  text-align: center;
  margin: 0 auto 1.25rem auto;
  max-width: 750px;
}

.section-head h1,
.section-head h2,
.section-head h3 {
  margin: 0;
}

.section-head.alt h1,
.section-head.alt .h1,
.section-head.alt h2,
.section-head.alt .h2 {
  font-weight: 400;
  color: #4c4740;
}

.intro {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 1.875rem auto;
  font-size: 1.125rem;
  font-family: Noto serif, serif;
}

.intro p:last-child {
  margin-bottom: 0;
}

.narrow-col {
  max-width: 800px;
  margin: 0 auto;
}

/* Pager */
.pager {
  margin: 0 0 1.875rem 0;
  text-align: center;
  position: relative;
}

.pager li {
  display: inline-block;
  vertical-align: top;
  padding: 0 0.125rem;
  margin: 0;
  background: none;
}

.pager li a {
  font-size: 0.9375rem;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  border: 2px solid #97928e;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  color: #97928e;
  transition: 0.3s all;
}

.pager li a:hover {
  border-color: #7a6e60;
  background: #7a6e60;
  color: #fff;
}

.pager li.active a {
  background: #7a6e60;
  border-color: #7a6e60;
  color: #fff;
  text-decoration: none;
}

.pager .prev {
  position: absolute;
  left: 0;
  top: 0;
}

.pager .next {
  position: absolute;
  right: 0;
  top: 0;
}

.pager .counter {
  display: none;
}

/* Cookie Notice */
.cookie-notice {
  font-size: 12px;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 100%;
  padding: 0.625rem 0.9375rem;
  background: rgba(0, 0, 0, 0.9);
  color: #ccc;
  text-align: center;
}

.cookie-button {
  display: inline-block;
  padding: 2px 10px;
  margin-left: 25px;
  background: #7a6e60;
  color: #fff;
  text-align: center;
}

.cookie-button:hover {
  background: #cbaf65;
  color: #fff;
}

.cookie-test {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 9.375rem;
  padding: 1.5625rem 0.9375rem;
  background: rgba(50, 50, 50, 0.95);
  color: #ccc;
  text-align: center;
}

/* Viewer */
.viewer {
  width: 100%;
}

/* Box */
.box {
  padding: 1.875rem;
  background: #f4f3f1;
}

/* Responsive iframe */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*================================= Various Content =================================*/
.last {
  margin-bottom: 0 !important;
}

ul.contact-list li {
  line-height: 24px;
  padding: 0 0 0 1.875rem;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.contact-list li .icon {
  position: absolute;
  left: 0;
  top: 0.125rem;
  color: #7a6e60;
}

ul.contact-list li a {
  color: rgba(125, 123, 121, 0.83);
  text-decoration: none;
}

ul.contact-list li a:hover {
  color: #7d7b79;
  text-decoration: underline;
}

/* Buttons */
.btn {
  font-family: Noto serif, serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3em;
  padding: 0 1.875rem;
  height: 3.125rem;
  border: 2px solid #7a6e60;
  background: #7a6e60;
  color: #fff;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  border-radius: 1.875rem;
  transition: 0.3s all;
}

.btn:hover {
  background-color: #cbaf65;
  border-color: #cbaf65;
}

.btn.line {
  background: none;
  color: #7a6e60;
}

.btn.line:hover {
  background: #cbaf65;
  border-color: #cbaf65;
  color: #fff;
}

.btn.small {
  font-family: Noto serif, serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.3em;
  padding: 0 1.25rem;
  height: 2.5rem;
}

/* Vertical align */
.va-wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.va-m {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.va-t {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.va-b {
  display: table-cell;
  vertical-align: bottom;
  height: 100%;
}

/* Icons */
svg {
  pointer-events: none;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  fill: currentColor;
}

/* Hidden content */
.sh-content {
  display: none;
}

/*================================= Columns =================================*/
.ib {
  font-family: Arial, sans-serif;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}

.ib > * {
  font-family: Roboto, sans-serif;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  letter-spacing: normal;
  word-spacing: normal;
}

/************************************************************************************
ENTRY
*************************************************************************************/
.entry {
  margin: 0;
}

.entry h2 {
  font-size: 2rem;
  line-height: 1.2em;
  margin: 2.5rem 0 1.25rem 0;
  color: #4c4740;
}

.entry h3 {
  font-size: 1.5rem;
  line-height: 1.2em;
  margin: 2.5rem 0 1.25rem 0;
  color: #4c4740;
}

.entry ul li {
  position: relative;
}

.entry ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background: #000;
}

/************************************************************************************
TABLES
*************************************************************************************/
.entry {
  width: 100%;
  margin: 0 0 1.875rem 0;
}

.entry table {
  width: 100%;
  margin: 0 0 1.875rem 0;
}

.entry table th {
  font-weight: normal;
  border-bottom: 0 solid #eaeaea;
  padding: 0.9375rem 1.875rem;
  background: #cbaf65;
  color: #fff;
  text-align: left;
}

.entry table td {
  border-bottom: 1px solid #eaeaea;
  padding: 0.9375rem 1.875rem;
}

/************************************************************************************
FORMS
*************************************************************************************/
form.std label,
.form.std label {
  font-family: Noto serif, serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.3em;
  color: #645d52;
  margin: 0 0 0.625rem 0;
  display: block;
}

form.std input.text,
.form.std input.text {
  font-family: Noto serif, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  color: #7d7b79;
  border: 2px solid #cbc8c6;
  height: 3.125rem;
  padding: 0 0.9375rem;
  width: 100%;
}

form.std input.text[required='required'],
.form.std input.text[required='required'] {
  background-image: url("../img/req.png");
  background-position: right center;
  background-repeat: no-repeat;
}

form.std textarea,
.form.std textarea {
  line-height: 1.3em;
  resize: vertical;
  padding: 0.625rem 0.9375rem;
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 150px;
  min-height: 150px;
  max-height: 300px;
  display: block;
}

form.std select,
.form.std select {
  min-width: 120px;
}

form.std option,
.form.std option {
  font-family: Noto serif, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  padding: 0.125rem 0.9375rem;
}

form.std .cell,
.form.std .cell {
  margin-bottom: 1.25rem;
  position: relative;
}

form.std .toggle,
.form.std .toggle {
  position: relative;
  padding: 0 0 0 1.875rem;
}

form.std .toggle label,
.form.std .toggle label {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

form.std .toggle .note,
.form.std .toggle .note {
  font-size: 1rem;
  max-width: 100%;
}

form.std .radio,
form.std .checker,
.form.std .radio,
.form.std .checker {
  position: absolute;
  left: 0;
  top: 0;
}

/* Form filter */
form.form-filter-cr fieldset {
  position: relative;
  margin-bottom: 1.25rem;
}

form.form-filter-cr fieldset .head {
  margin-bottom: 1.25rem;
  line-height: 1.3em;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

form.form-filter-cr fieldset .head legend {
  font-family: Noto serif, serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3em;
  display: block;
  margin-bottom: 1.25rem;
  flex-grow: 1;
  flex: 0 0 100%;
}

form.form-filter-cr .cancel {
  display: none;
}

form.form-filter-cr .cancel.active {
  display: block;
}

form.form-filter-cr .cell {
  margin-bottom: 0.625rem;
}

form.form-filter-cr .toggle {
  padding-left: 1.875rem;
}

form.form-filter-cr .regions {
  display: none;
}

form.form-filter-cr .foot {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

form.form-filter-cr .foot .cancel {
  margin-left: 1.25rem;
}

/* Form events */
form.form-filter-events {
  padding: 2.5rem 0;
}

form.form-filter-events .form-inner {
  display: flex;
}

form.form-filter-events .inputs {
  flex-grow: 1;
}

form.form-filter-events label {
  height: 20px;
}

form.form-filter-events .cell {
  margin-bottom: 0.625rem;
}

form.form-filter-events .btn-set {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1em;
  padding-left: 20px;
}

form.form-filter-events .btn {
  margin-left: 20px;
}

form.form-filter-events .cancel {
  white-space: nowrap;
}

/* Form search */
form.form-search input.text {
  font-weight: 600;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 30px;
  height: 3.75rem;
  line-height: 1.25rem;
  padding: 0 1.25rem;
  letter-spacing: 0.05em;
  background: none;
}

form.form-search input.text:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

form.form-search input.text::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

form.form-search input.text:-ms-input-placeholder {
  color: #fff;
}

form.form-search input.text::-webkit-input-placeholder {
  color: #fff;
}

form.form-search .btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
  line-height: 3.75rem;
  background: none;
  border: none;
}

.header form.form-search input {
  height: 3.125rem;
  padding-right: 2.5rem;
  font-family: Roboto, sans-serif;
  border: 0;
  background: #ffffff55;
  color: white;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.12px;
}

.header form.form-search input:-moz-placeholder {
  color: white;
  opacity: 1;
}

.header form.form-search input::-moz-placeholder {
  color: white;
  opacity: 1;
}

.header form.form-search input:-ms-input-placeholder {
  color: white;
}

.header form.form-search input::-webkit-input-placeholder {
  color: white;
}

.header form.form-search .cell {
  position: static;
  margin: 0;
  display: flex;
}

.header form.form-search .cell.loading .btn {
  display: none;
}

.header form.form-search .cell.loading .loader {
  width: 3.125rem;
  height: 3.125rem;
  margin-left: -3.125rem;
  flex: 0 0 3.125rem;
  display: flex;
  justify-content: center;
  position: static;
}

.header form.form-search .btn {
  width: 3.125rem;
  height: 3.125rem;
  margin-left: -3.125rem;
  flex: 0 0 3.125rem;
  display: flex;
  justify-content: center;
  position: static;
}

.header form.form-search .loader {
  display: none;
}

.header.alt form.form-search input.text {
  border-color: #d6d1ca;
  background: #d6d1ca;
  color: white;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.12px;
}

.header.alt form.form-search input.text:-moz-placeholder {
  color: white;
  opacity: 1;
}

.header.alt form.form-search input.text::-moz-placeholder {
  color: white;
  opacity: 1;
}

.header.alt form.form-search input.text:-ms-input-placeholder {
  color: white;
}

.header.alt form.form-search input.text::-webkit-input-placeholder {
  color: white;
}

form.form-search-page {
  margin-bottom: 2.5rem;
  padding: 1.25rem;
  background: #f9f8f6;
}

form.form-search-page .cell {
  display: flex;
  margin-bottom: 0;
}

form.form-search-page .cell .btn-set {
  padding-left: 0.625rem;
  flex: 0 0 5rem;
  position: relative;
}

form.form-search-page .cell .loader {
  display: none;
}

form.form-search-page .cell.loading .loader {
  position: absolute;
  left: -3.75rem;
  top: 0;
  width: 3.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
  line-height: 3.75rem;
  background: none;
  border: none;
}

/* Form contact */
form.form-contact {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

form.form-contact label {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #97928e;
  text-transform: uppercase;
}

form.form-contact .cell {
  padding-left: auto;
}

form.form-contact .btn-set {
  text-align: center;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

/* Form subscribe */
form.form-subscribe .input {
  display: flex;
}

form.form-subscribe .btn {
  margin-left: 10px;
}

/************************************************************************************
FLASH MESSAGES
*************************************************************************************/
.flash-message {
  font: normal 16px/24px Roboto, sans-serif;
  background: #fc796b;
  color: #fff;
  width: 100%;
  padding: 0.9375rem 3.125rem 0.9375rem 1.25rem;
  margin: 0 0 1.25rem 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 0 0 4px 4px;
}

.flash-message .close {
  font: normal 36px/24px Roboto, sans-serif;
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 18px;
  text-align: center;
  color: #c96055;
  border: none;
  display: block;
  position: absolute;
  right: 17px;
  top: 16px;
  background: none;
}

.flash-message .close:hover {
  color: #fff !important;
}

.flash-message.alert-danger {
  background: #fae7ea;
  color: #e5547a;
}

.flash-message.alert-danger .close {
  color: #e5547a;
}

.flash-message.alert-success {
  background: #ebf5bc;
  color: #98b028;
}

.flash-message.alert-success .close {
  color: #98b028;
}

/************************************************************************************
TABS
*************************************************************************************/
.tabs {
  position: relative;
}

.tabs.alt .tab-nav li a.active {
  background: #f9f8f6;
}

.tab-nav ul {
  margin: 0 -0.5rem;
  padding: 0;
  position: relative;
  top: 2px;
  display: flex;
  justify-content: center;
}

.tab-nav li {
  list-style-type: none;
  padding: 0 0.5rem;
  background: none;
  margin: 0;
}

.tab-nav li a {
  font-family: Noto serif, serif;
  font-size: 1.25rem;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  outline: 0;
  padding: 0.9375rem 1.875rem;
  color: #ADA295;
  border: 2px solid #dedbd7;
  border-bottom: none;
  text-align: center;
  border-radius: 10px 10px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-nav li a .txt {
  position: relative;
  padding-left: 3.5625rem;
  display: inline-block;
  vertical-align: top;
}

.tab-nav li a .txt .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.tab-nav li a .txt .icon-czech {
  top: -8px;
}

.tab-nav li a:hover {
  background: #f9f8f6;
}

.tab-nav li a.active {
  background: #fff;
  background: #efeeec;
  border-color: #bcb6af;
}

.tab-content {
  border-top: 2px solid #bcb6af;
}

/************************************************************************************
QTIP
*************************************************************************************/
.qtip-default {
  font: normal 14px/22px Roboto, sans-serif;
  padding: 5px;
  background-color: #cdaf65;
  color: #cdaf65;
  border: none;
}

.qtip-content {
  font-size: 13px;
  color: #fff;
}

/************************************************************************************
FANCYBOX
*************************************************************************************/
body .fancybox-thumbs {
  top: auto;
  width: auto;
  bottom: 10px;
  left: 0;
  right: 0;
  height: 80px;
  background: transparent;
  text-align: center;
}

body .fancybox-thumbs .fancybox-inner {
  right: 0;
}

body .fancybox-thumbs > ul {
  width: 100% !important;
}

body .fancybox-thumbs > ul > li {
  float: none;
  display: inline-block;
  vertical-align: top;
}

body .fancybox-slide {
  padding: 20px;
}

body .fancybox-slide > * {
  overflow: visible;
}

body .fancybox-close-small {
  background: #000;
  border-radius: 50%;
  right: -20px;
  top: -20px;
}

body .fancybox-close-small:hover {
  background: #fff;
}

body .fancybox-close-small:hover:after {
  background: none;
  color: #000;
}

body.compensate-for-scrollbar {
  margin: 0 !important;
}

/************************************************************************************
DATEPICKER
*************************************************************************************/
body .ui-datepicker.ui-widget {
  font-size: 0.9em;
  width: 20em;
}

body .ui-datepicker.ui-widget-content {
  background: #fff;
}

body .ui-datepicker td span,
body .ui-datepicker td a {
  padding: 0.5em;
  line-height: 1.5em;
}

body .ui-datepicker .ui-widget-header {
  background: #e65828;
  color: #fff;
  border: none;
}

body .ui-datepicker .ui-datepicker-header {
  padding: 0.5em 0;
}

body .ui-datepicker .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-next {
  top: 6px;
  cursor: pointer;
}

body .ui-datepicker .ui-state-default,
body .ui-datepicker .ui-widget-content .ui-state-default,
body .ui-datepicker .ui-widget-header .ui-state-default {
  background: #e5f2ff;
  border: none;
}

body .ui-datepicker .ui-datepicker-prev span {
  position: absolute;
  left: 10px;
  top: 50%;
  margin: -5px 0 0 0;
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-left: 0.2em solid #fff;
  border-bottom: 0.2em solid #fff;
  transform: rotate(45deg);
  display: block;
}

body .ui-datepicker .ui-datepicker-next span {
  position: absolute;
  left: auto;
  right: 10px;
  top: 50%;
  margin: -5px 0 0 0;
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-right: 0.2em solid #fff;
  border-top: 0.2em solid #fff;
  transform: rotate(45deg);
  display: block;
}

body .ui-datepicker .ui-state-hover,
body .ui-datepicker .ui-widget-content .ui-state-hover,
body .ui-datepicker .ui-widget-header .ui-state-hover,
body .ui-datepicker .ui-state-focus,
body .ui-datepicker .ui-widget-content .ui-state-focus,
body .ui-datepicker .ui-widget-header .ui-state-focus {
  border: none;
  background: #e65828;
  color: #fff;
}

/************************************************************************************
MODAL
*************************************************************************************/
.modal-box {
  display: none;
  background: #eee;
}

.modal-content {
  padding: 2.5rem;
}

/* Modal contact */
.modal-contact {
  max-width: 600px;
}

/* Modal instagram */
.modal-instagram {
  max-width: 935px;
  padding: 0;
  background: #fff;
}

.modal-instagram .modal-content {
  padding: 0;
}

.modal-instagram .img {
  margin-right: 20.9375rem;
}

.modal-instagram .text {
  font-size: 14px;
  line-height: 1.3em;
  color: #262626;
  width: 20.9375rem;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.modal-instagram .text .inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2.125rem 1.5rem 1.25rem 1.5rem;
}

.modal-instagram .text .desc {
  display: flex;
  flex: 1 1 auto;
  order: 1;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: 1.25rem;
}

.modal-instagram .text .desc .link {
  color: #262626;
  font-weight: 600;
  text-decoration: none;
}

.modal-instagram .text .info {
  display: flex;
  flex: 0 1 auto;
  order: 2;
  flex-direction: row;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 5px 0;
}

.modal-instagram .text .info .likes {
  margin-bottom: 5px;
}

.modal-instagram .text .info .date {
  font-size: 10px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 0;
}

/* Modal campaign */
.modal-campaign {
  max-width: 650px;
  background: none;
}

.modal-campaign .modal-content {
  background: #fff;
}

.modal-campaign .modal-content p {
  margin: 0;
}

/************************************************************************************
FIXER
*************************************************************************************/
.fixer-start {
  position: relative;
}

.fixer {
  position: relative;
  top: 0;
  max-width: px;
}

.fixer.fixed {
  position: fixed;
}

/************************************************************************************
UNIFORM
*************************************************************************************/
div.checker {
  position: relative;
}

div.checker span {
  display: inline-block;
  text-align: center;
  background: url("../img/uniform/uniform.svg") -52px -11px no-repeat;
  background-position: -3.25rem -0.6875rem;
  background-size: 5rem;
  transition: 0.3s all;
}

div.checker span.checked {
  background-position: -3.25rem -3.1875rem;
}

div.checker input {
  margin: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  border: none;
  background: none;
  display: inline-block;
  vertical-align: top;
}

div.checker.active span {
  background-position: -3.25rem -3.1875rem;
}

div.checker.active span.checked {
  background-position: -3.25rem -0.6875rem;
}

div.checker.hover span,
div.checker.focus span {
  background-position: -3.25rem -0.6875rem;
}

div.checker.hover span.checked,
div.checker.focus span.checked {
  background-position: -3.25rem -3.1875rem;
}

div.checker.hover.active span,
div.checker.focus.active span {
  background-position: -3.25rem -0.6875rem;
}

div.checker.hover.active span.checked,
div.checker.focus.active span.checked {
  background-position: -3.25rem -3.1875rem;
}

div.checker,
div.checker span,
div.checker input {
  width: 1.25rem;
  height: 1.25rem;
}

/* Radio */
div.radio {
  position: relative;
}

div.radio span {
  display: inline-block;
  text-align: center;
  background: url("../img/uniform/uniform.svg") -12px -11px no-repeat;
  transition: 0.3s all;
}

div.radio span.checked {
  background-position: -0.75rem -3.1875rem;
}

div.radio input {
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  margin: 0;
  border: none;
  background: none;
  display: inline-block;
  text-align: center;
}

div.radio.active span {
  background-position: -0.75rem -3.1875rem;
}

div.radio.active span.checked {
  background-position: -0.75rem -0.6875rem;
}

div.radio.hover span,
div.radio.focus span {
  background-position: -0.75rem -3.1875rem;
}

div.radio.hover span.checked,
div.radio.focus span.checked {
  background-position: -0.75rem -3.1875rem;
}

div.radio.hover.active span,
div.radio.focus.active span {
  background-position: -0.75rem -3.1875rem;
}

div.radio.hover.active span.checked,
div.radio.focus.active span.checked {
  background-position: -0.75rem -3.1875rem;
}

div.radio,
div.radio span,
div.radio input {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

/************************************************************************************
SUDO SLIDERS
*************************************************************************************/
.slider {
  overflow: hidden;
  background: none;
}

.slider ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: block;
}

.slider li.panel {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: block;
  overflow: hidden;
  background: none;
}

.slider-nav {
  position: static;
  text-align: center;
}

.slider-nav ol {
  margin: 0 -0.25rem;
}

.slider-nav li {
  padding: 0 0.25rem;
  background: none;
  display: inline-block;
  font-size: 0;
}

.slider-nav li:before {
  content: '';
}

.slider-nav li a {
  width: 0.625rem;
  height: 0.625rem;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  text-indent: -80000px;
  border-radius: 50%;
}

.slider-nav li a:hover {
  background: #fff;
}

.slider-nav li a.current {
  background: none;
  border: 3px solid #fff;
  border-width: 0.1875rem;
}

.slider-nav .prev {
  position: absolute;
  left: 0;
  top: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  display: flex;
}

.slider-nav .prev:hover {
  color: #cbaf65;
}

.slider-nav .prev .icon {
  width: 100%;
  height: 100%;
}

.slider-nav .next {
  position: absolute;
  right: 0;
  top: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  display: flex;
}

.slider-nav .next:hover {
  color: #cbaf65;
}

.slider-nav .next .icon {
  width: 100%;
  height: 100%;
}

/* Teaser slider */
.teaser-slider {
  position: relative;
}

.teaser-slider .slider {
  height: auto !important;
}

.teaser-slider .slider li.panel {
  margin: 0;
  display: none;
  background-size: cover !important;
  background-position: center center !important;
}

.teaser-slider .slider li.panel.shade:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.teaser-slider .slider li.panel:first-child {
  display: block;
}

.teaser-detail .teaser-content {
  height: auto;
}

.teaser-detail .slider-nav {
  bottom: 50px;
}

.teaser-detail:after {
  height: 100px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,.65+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */
}

.teaser .slider-nav {
  position: absolute;
  left: 50%;
  bottom: 1.875rem;
  z-index: 100;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.teaser .slider-nav ol {
  display: flex;
}

/* Testimonial slider */
.testimonial-slider {
  position: relative;
  padding: 0 3.75rem;
}

.testimonial-slider .slider-nav ol li a {
  background: #7a6e60;
}

.testimonial-slider .slider-nav ol li.current a {
  background: #cbaf65;
}

/************************************************************************************
TRANSITIONS & ANIMATIONS
*************************************************************************************/
a {
  transition: color 0.1s;
  -moz-transition: color 0.1s;
  -webkit-transition: color 0.1s;
  -o-transition: color 0.1s;
  -ms-transition: color 0.1s;
}

.tag-list ul li a {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
}

/************************************************************************************
CLEARFIX
*************************************************************************************/
/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/************************************************************************************
HEADER
*************************************************************************************/
.header {
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header .container {
  width: 100%;
  max-width: 1540px;
}

.header .inner {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  height: 6.25rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  display: block;
  max-width: 10.625rem;
  padding-right: 0.9375rem;
}

.header .search {
  width: 100%;
  max-width: 10.625rem;
  height: 3.125rem;
}

.header .header-r {
  display: flex;
  align-items: center;
}

.header a {
  letter-spacing: 1.12px;
}

.header .social-nav ul li a {
  background: #ffffff55;
  border-color: #ffffff55;
  border: 0;
  color: white;
  width: 3.0625rem;
  height: 3.0625rem;
  transition: all 0.2s;
}

.header .social-nav ul li a .icon {
  fill: white;
  width: 20px;
  height: 20px;
}

.header .social-nav ul li a:hover {
  background: #ffffff88;
  color: #fff;
}

.header.alt {
  position: relative;
  max-width: 1600px;
  background: #fff;
  margin: 0 auto;
  border-bottom: 1px solid #eaeaea;
}

.header.alt .inner {
  background: #fff;
}

.header.alt a {
  color: #555351;
}

.header.alt a:hover {
  color: #4c4740;
}

.header.alt form.form-search .btn {
  color: white;
}

.header.alt .main-nav ul li a:after {
  background: #4c4740;
}

.header.alt .main-nav ul li.menu-active a:after {
  background: #4c4740;
}

.header.alt .social-nav ul li a {
  background: #d6d1ca;
  border-color: #d6d1ca;
  color: #7a6e60;
}

.header.alt .social-nav ul li a:hover {
  background: #7a6e60;
  color: #fff;
  opacity: 1;
}

.header.alt .nav-btn .line-1,
.header.alt .nav-btn .line-2,
.header.alt .nav-btn .line-3 {
  background: #4c4740;
}

.social-nav {
  margin-right: 0.625rem;
}

.social-nav ul {
  margin: 0 -0.3125rem;
  display: flex;
}

.social-nav ul li {
  margin: 0;
  padding: 0 0.3125rem;
}

.social-nav ul li a {
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background: #ADA295;
}

.social-nav ul li a:hover {
  background: #fff;
  color: #7a6e60;
}

.social-nav ul li a .icon {
  width: 0.9375rem;
  height: 0.9375rem;
}

.teaser {
  margin: 0;
  position: relative;
  z-index: 50;
}

.teaser-content {
  height: 757px;
  min-height: 500px;
  max-height: 100vh;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.teaser-content h1 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}

.teaser-content .medium {
  width: 100%;
  max-width: 660px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 50;
}

.teaser-content .medium:last-child {
  margin-bottom: 0;
}

.teaser-content .desc {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0.9375rem 5.625rem 0.9375rem;
  text-align: right;
  z-index: 50;
}

.teaser-content .desc a {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
}

.teaser-content .desc a:hover {
  text-decoration: none;
}

.main-nav {
  margin: 0 0.625rem;
  text-align: center;
}

.main-nav ul {
  margin: 0 -0.75rem;
  display: flex;
}

.main-nav ul li {
  padding: 0 0.75rem;
  margin: 0;
  background: none;
}

.main-nav ul li a {
  font-size: 0.875rem;
  font-weight: 600;
  color: #dcdcdc;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}

.main-nav ul li a:after {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  background: #fff;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: 0.3s all;
}

.main-nav ul li a:hover {
  color: #fff;
}

.main-nav ul li a:hover:after {
  width: 100%;
}

.main-nav ul li.menu-active a:after {
  width: 100%;
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  margin-left: 0.9375rem;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
}

.nav-btn .lines {
  width: 30px;
  height: 4px;
  flex: 0 0 30px;
  display: block;
  position: relative;
  position: absolute;
  top: 50%;
  margin-top: -2px;
}

.nav-btn .line-1,
.nav-btn .line-2,
.nav-btn .line-3 {
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: 0.1s;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: 0.28571rem center;
}

.nav-btn .line-1 {
  top: 0;
  transition: opacity 0.1s 0.1s ease;
}

.nav-btn .line-2 {
  top: 10px;
  transform-origin: 50% 50%;
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
  transition: top 0.1s 0.1s ease, transform 0.1s ease;
}

.nav-btn .line-3 {
  top: -10px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
  transition: top 0.1s 0.1s ease, transform 0.1s ease;
}

.nav-btn.active .line-1 {
  transition: opacity 0.1s 0s ease;
  background: transparent;
  opacity: 0;
}

.nav-btn.active .line-2 {
  transform: rotate3d(0, 0, 1, 45deg);
  transition: top 0.1s ease, transform 0.1s 0.1s ease;
  top: 0;
}

.nav-btn.active .line-3 {
  transform: rotate3d(0, 0, 1, -45deg);
  transition: top 0.1s ease, transform 0.1s 0.1s ease;
  top: 0;
}

.mobile-nav {
  background: #4c4740;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 95;
  overflow-y: auto;
  width: 100%;
  padding: 120px 0 40px 0;
  transition: 0.3s all;
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
  margin-top: -5px;
}

.mobile-nav.active {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  margin-top: 0;
}

.mobile-nav .close {
  font-size: 30px;
  font-weight: 400;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: none !important;
}

.mobile-nav .close .icon {
  width: 15px;
  height: 15px;
}

.mobile-nav .close:hover {
  color: #fff;
}

.mobile-nav .nav ul {
  margin: 0 0 1.875rem 0;
  padding: 0;
}

.mobile-nav .nav ul li {
  color: #fff;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

.mobile-nav .nav ul li.active a {
  color: #ffa70e;
}

.mobile-nav .nav ul li.last {
  border: none;
}

.mobile-nav .nav ul li a {
  font-size: 16px;
  font-weight: 400;
  display: block;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  position: relative;
  text-decoration: none;
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
}

/************************************************************************************
CONTENT
*************************************************************************************/
.sights {
  margin: 0 auto;
}

.sights .item-list {
  margin: 0 -0.625rem -1.875rem -0.625rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.sights .item-list.loading:after {
  content: '';
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  z-index: 100;
  background: url("../img/spinner.svg") cnter center no-repeat;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sights .item-list.loading .item {
  opacity: .5;
}

.sights .item {
  margin: 0 0 1.875rem 0;
  padding: 0 0.625rem;
  display: flex;
  transition: 0.3s all;
}

.sights .item .img {
  display: block;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.sights .item .img img {
  transition: 0.3s all;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  width: 100%;
}

.sights .item .img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background: rgba(63, 61, 59, 0.5);
}

.sights .item .img:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  z-index: 30;
  width: 100%;
  height: 17px;
  background: url("../img/sight-overlay.svg") center 0 no-repeat;
}

.sights .item .img h3 {
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 400;
  margin: -1.0625rem 0 0 0;
  color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 40;
  width: 100%;
  padding: 10px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.sights .item .img h3:after {
  content: '';
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: -1.875rem;
  width: 3.75rem;
  height: 2px;
  margin-left: -1.875rem;
}

.sights .item:not(.disabled) .img:hover:before {
  background: rgba(63, 61, 59, 0.3);
}

.sights .item:not(.disabled) .img:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.sights .item .text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff;
  color: #7d7b79;
  padding: 2.1875rem 1.875rem 1.5625rem 1.875rem;
  text-align: center;
}

.sights .item .text .desc {
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 0.3125rem;
}

.sights .item .text .no-detail {
  max-width: 200px;
  min-height: 78px;
  padding-top: 0.625rem;
  margin: 0 auto;
  background: url("../img/closed.svg") center center no-repeat;
}

.sights .item-inner {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  font-family: Noto serif, serif;
  font-size: 0.9375rem;
}

.sights .foot {
  text-align: center;
  padding-top: 1.875rem;
}

.opening-info {
  font-family: Noto serif,serif;
  font-size: 0.9375rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.625rem;
}

.opening-info .txt {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.opening-info .txt .val {
  padding-left: 5px;
  white-space: nowrap;
}

.opening-info .hint {
  display: inline-flex;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 10px;
}

.opening-info .hint .icon {
  vertical-align: middle;
}

.opening-info .status {
  width: 0.5rem;
  height: 0.5rem;
  flex: 0 0 0.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
  position: relative;
  top: 0;
  border-radius: 50%;
}

.opening-info .status.open {
  background: #70ad3a;
}

.opening-info .status.closed {
  background: #e91144;
}

.cat-list {
  margin: 0;
}

.cat-list ul {
  margin: 0 0 -0.625rem 0;
  text-align: center;
}

.cat-list ul li {
  color: #aa9e90;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0.625rem 0;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  padding-right: 5px;
}

.cat-list ul li a {
  color: #aa9e90;
  display: block;
}

.cat-list ul li a .js-qtip {
  display: block;
  cursor: help;
}

.cat-list ul li a:hover {
  color: #4c4740;
}

.cat-list ul li .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.cat-list ul li:before {
  display: none;
}

.posts {
  margin: 0 auto;
}

.posts .intro {
  font-size: 1.125rem;
  line-height: 1.625em;
  margin-bottom: 5rem;
}

.posts .item-inner {
  display: flex;
}

.posts .item {
  margin-bottom: 3.75rem;
}

.posts .item .date {
  font-size: 0.875rem;
  font-weight: 600;
  color: #97928e;
  text-transform: uppercase;
  margin: 0.625rem 0;
}

.posts .item .img {
  flex: 0 0 50%;
  max-width: 50%;
  float: left;
  display: block;
}

.posts .item .text {
  flex: 0 0 50%;
  max-width: 50%;
}

.posts .item .text .inner {
  padding-left: 1.25rem;
}

.posts .item:nth-child(4n) .img, .posts .item:nth-child(4n+3) .img {
  order: 1;
}

.posts .item:nth-child(4n) .text, .posts .item:nth-child(4n+3) .text {
  order: 0;
}

.posts .item:nth-child(4n) .text .inner, .posts .item:nth-child(4n+3) .text .inner {
  padding-left: 0;
  padding-right: 1.25rem;
}

.posts .foot {
  text-align: center;
}

.posts .foot .btn:hover {
  background: #fff;
  color: #7a6e60;
}

.posts.tips .foot .btn:hover {
  background: #cbaf65;
  color: #fff;
}

.tag-list ul {
  margin: 0 -0.3125rem;
  display: flex;
  flex-wrap: wrap;
}

.tag-list ul li {
  padding: 0 0.3125rem;
  margin: 0 0 0.3125rem 0;
  /*a:hover {
				border-color: #4c4740;
				color: #4c4740;
				background: #4c4740;
				color: #fff;
			}*/
}

.tag-list ul li a {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: #97928e;
  border: 2px solid #97928e;
  padding: 0.125rem 0.5rem;
  letter-spacing: .05em;
  text-decoration: none;
  border-radius: 4px;
  cursor: default;
}

.tag-list.center ul {
  justify-content: center;
}

.block.alt-2 .posts .item h3 {
  color: #fff;
}

.block.alt-2 .posts .item h3 a {
  color: #fff;
}

.block.alt-2 .tag-list ul li a:hover {
  background: #97928e;
  border-color: #97928e;
  color: #fff;
}

.post-detail {
  max-width: 800px;
  margin: 0 auto;
}

.tips .filter {
  padding: 2.5rem;
  background: #f9f8f6;
}

.tips .intro {
  margin-bottom: 2.5rem;
}

.tips-related .section-head {
  margin-bottom: 2.5rem;
}

.tips-related .item-list {
  margin-bottom: -3.75rem;
}

.tips-side .item {
  margin-bottom: 1.875rem;
  display: flex;
}

.tips-side .item .item-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tips-side .item .text {
  padding: 0.9375rem;
  background: #fff;
  flex-grow: 1;
}

.gallery {
  margin: 0 auto;
}

.gallery .item-list {
  margin: 0 -0.625rem;
}

.gallery .item {
  width: 16.666666%;
  padding: 0 0.625rem;
  margin-bottom: 1.25rem;
}

.gallery .item a {
  display: block;
  position: relative;
  overflow: hidden;
}

.gallery .item a img {
  transition: 0.3s all;
}

.gallery .item a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s all;
}

.gallery .item a:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.gallery .item a:hover:after {
  background: rgba(0, 0, 0, 0.2);
}

.instagram .item-list {
  margin-left: -1px;
  margin-right: -1px;
}

.instagram .item {
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 2px;
}

.instagram .item .img {
  position: relative;
  display: block;
  height: 100%;
}

.instagram .item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instagram .item .img:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s all;
}

.instagram .item .img:hover:after {
  background: rgba(0, 0, 0, 0.3);
}

.instagram .item .img:hover .info {
  top: 0;
  opacity: 1;
}

.instagram .item .img .info {
  position: absolute;
  z-index: 30;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s all;
}

.instagram .item .img .info a {
  text-decoration: none;
  color: #fff;
}

.instagram .item .img .info ul {
  margin: 0;
  display: flex;
}

.instagram .item .img .info ul li {
  padding: 0 10px;
  margin: 0;
  display: flex;
  color: #fff;
}

.detail-info {
  margin: 0 auto;
}

.detail-info h2 {
  margin-bottom: 1.875rem;
  color: #7d7b79;
}

.detail-info .section-head h2 {
  margin: 0;
  font-weight: 600;
  color: #7d7b79;
}

.detail-info .intro-head {
  text-align: center;
  margin-bottom: 2.5rem;
}

.detail-info .intro-head h2 {
  margin: 0 0 1.25rem 0;
  font-weight: 600;
  color: #7d7b79;
}

.detail-info .contact-info {
  margin-bottom: 2.5rem;
}

.detail-info .contact-info a {
  color: #7d7b79;
}

.detail-info .contact-info a:hover {
  color: #645d52;
}

.detail-info .contact-info .contact-list li:before {
  content: none;
}

.detail-info .contact-info .contact-list li.route {
  padding-top: 0;
  font-weight: 600;
}

.detail-info .contact-info .contact-list li.route a {
  color: #cdaf65;
  text-decoration: underline;
}

.detail-info .contact-info .contact-list li.route a:hover {
  text-decoration: none;
}

.detail-info .contact-info .smap-wrapper {
  height: 300px;
  margin-bottom: 1.875rem;
}

.opening {
  margin-bottom: 2.5rem;
  max-width: 460px;
}

.opening table {
  width: 100%;
}

.opening table th {
  border-bottom: 0 solid #eaeaea;
  text-align: left;
}

.opening table td {
  border-bottom: 1px solid #eaeaea;
  padding: 0.5rem 1.25rem;
  vertical-align: middle;
}

.opening table td:first-child {
  padding-left: 0;
}

.opening table td:last-child {
  padding-right: 0;
  text-align: right;
}

.opening table td.month, .opening table td.head {
  font-weight: 600;
}

.opening table td.hour {
  text-align: center;
}

.opening table td.action {
  width: 3.75rem;
}

.opening table p {
  margin-bottom: 0;
}

.opening table .schedule-hint {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  vertical-align: middle;
}

.schedule-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 2px solid #eaeaea;
}

.schedule-nav .nav-prev {
  order: 1;
  width: 1.875rem;
}

.schedule-nav .nav-next {
  order: 3;
  width: 1.875rem;
}

.schedule-nav .heading {
  order: 2;
  font-family: Noto serif,serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3em;
  padding: 0 0.625rem;
  color: #cdaf65;
}

.schedule-nav .link {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  background: #cdaf65;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.schedule-nav .link .icon {
  width: 1.875rem;
  height: 1.875rem;
  color: #fff;
  display: none !important;
}

.schedule-nav .link:hover {
  color: #fff;
  background: #7a6e60;
}

.schedule-nav .link.hidden {
  display: none;
}

.schedule-nav .nav-prev .link:before {
  content: '';
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -5px;
  display: inline-block;
  width: .6em;
  height: .6em;
  border-left: .15em solid #fff;
  border-bottom: .15em solid #fff;
  transform: rotate(45deg);
  display: block;
}

.schedule-nav .nav-next .link:before {
  content: '';
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -5px;
  display: inline-block;
  width: .6em;
  height: .6em;
  border-right: .15em solid #fff;
  border-top: .15em solid #fff;
  transform: rotate(45deg);
  display: block;
}

.events {
  margin: 0;
}

.events .item-list {
  margin: 0;
}

.events .item-inner {
  border: 1px solid #eaeaea;
  padding: 2.8125rem 2.1875rem;
}

.events .item {
  width: 100%;
}

.events .item .img {
  float: left;
}

.events .item .text {
  padding-left: 23.125rem;
}

.events .item .text h3 {
  margin-bottom: 10px;
  color: #7d7b79;
}

.events .item .text .inner {
  padding: 0 0 0 2.1875rem;
  border-left: none;
  position: relative;
}

.events .item .text .date {
  font-size: 1rem;
  font-weight: 600;
  color: #7d7b79;
  margin-bottom: 0.625rem;
}

.events .item .text .actions {
  margin-top: 1.875rem;
}

.events .item .text .actions ul {
  margin: 0;
  display: table-row;
  width: 100%;
}

.events .item .text .actions ul li {
  display: table-cell;
  margin: 0;
  padding: 0;
}

.event-archive {
  margin: 0;
}

.event-archive .item-inner {
  padding: 0 0 30px 0;
  display: flex;
  border-bottom: 2px solid #eaeaea;
  width: 100%;
}

.event-archive .item {
  margin-bottom: 1.875rem;
  display: flex;
  background: white;
  border-radius: 10px;
}

.event-archive .item .tag-list {
  margin: 0 0.625rem 0 0;
}

.event-archive .item .tag-list li {
  margin: 2px 0;
}

.event-archive .item .img {
  flex: 0 0 11.25rem;
  max-width: 11.25rem;
}

.event-archive .item .text {
  padding-left: 1.875rem;
  flex-grow: 1;
}

.event-archive .item .meta {
  display: flex;
  flex-wrap: wrap;
}

.event-archive .item .desc {
  overflow: hidden;
}

.event-archive .item .date {
  font-size: 0.875rem;
  font-weight: 600;
  color: #97928e;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.event-archive .item .date .icon {
  width: 1.125rem;
  height: 1.125rem;
  flex: 0 0 1.125rem;
  margin-right: 0.625rem;
}

.event-archive .item .object-link {
  display: flex;
  align-items: center;
  margin: 0.625rem 0;
}

.event-archive .item .object-link .icon {
  margin-right: 5px;
}

.event-archive .item h3 {
  color: #7a6e60;
}

.date-switcher {
  margin: 0 0 3.75rem 0;
  position: relative;
}

.date-switcher.bottom {
  margin-top: 2.5rem;
}

.date-switcher .inner {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.date-switcher .head {
  padding: 0 40px;
}

.date-switcher .head h2 {
  margin: 0;
}

.date-switcher .nav-prev {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-switcher .nav-prev .txt {
  order: 1;
}

.date-switcher .nav-prev .icon {
  order: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #cdaf65;
  margin-right: 10px;
}

.date-switcher .nav-prev .icon:before {
  content: '';
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -5px;
  display: inline-block;
  width: .6em;
  height: .6em;
  border-left: .15em solid #fff;
  border-bottom: .15em solid #fff;
  transform: rotate(45deg);
  display: block;
}

.date-switcher .nav-next {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-switcher .nav-next .txt {
  order: 0;
}

.date-switcher .nav-next .icon {
  order: 1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #cdaf65;
  margin-left: 10px;
}

.date-switcher .nav-next .icon:before {
  content: '';
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -5px;
  display: inline-block;
  width: .6em;
  height: .6em;
  border-right: .15em solid #fff;
  border-top: .15em solid #fff;
  transform: rotate(45deg);
  display: block;
}

.main-cats {
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
}

.main-cats .item-list {
  margin-bottom: -1.25rem;
}

.main-cats .item {
  border-left: 1px dashed #e0dedc;
  margin-bottom: 1.25rem;
  display: flex;
}

.main-cats .item:first-child {
  border: none;
  justify-content: flex-end;
}

.main-cats .item:first-child .item-inner {
  padding-right: 1.25rem;
}

.main-cats .item .text {
  font-family: Noto serif,serif;
  font-size: 0.875rem;
  padding-left: 5.3125rem;
  max-width: 205px;
  width: 100%;
  text-align: left;
}

.main-cats .item .img {
  width: 5.3125rem;
  position: absolute;
  left: 0;
  top: 0;
  color: #ada295;
}

.main-cats .item .img .icon {
  display: block;
  margin: 0 auto;
  width: 3.4375rem;
  height: 3.4375rem;
}

.main-cats .item-inner {
  position: relative;
  min-height: 3.4375rem;
  display: flex;
  align-items: center;
}

.properties {
  display: table;
  width: 100%;
}

.properties .item-list {
  display: flex;
  justify-content: center;
}

.properties .item {
  font-size: 1.125rem;
  font-family: Noto serif,serif;
  line-height: 1.3em;
  font-weight: 400;
  text-align: center;
  padding: 1.875rem 0.9375rem;
}

.properties .item .head {
  font-size: 0.875rem;
  max-width: 120px;
  display: block;
  margin: 0 auto;
}

.properties .item .icon {
  width: 2.8125rem;
  height: 2.8125rem;
  color: #aa9e90;
  display: block;
  margin: 0 auto;
}

.about {
  margin: 0;
}

.text-image {
  margin-bottom: 7.5rem;
  display: flex;
}

.text-image .text {
  order: 0;
  width: 50%;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.text-image .img {
  order: 1;
  width: 50%;
  padding: 0 15px;
  margin: 0.9375rem 0;
  display: flex;
  align-items: center;
}

.text-image .img img {
  display: block;
  margin: 0 auto;
}

.text-image:nth-child(even) .text {
  order: 1;
}

.text-image:nth-child(even) .img {
  order: 0;
}

.partner .img img {
  display: block;
  margin: 0 auto;
}

.testimonials {
  max-width: 850px;
  margin: 0 auto;
}

.testimonials .item {
  font-size: 1.125rem;
  line-height: 1.8em;
  text-align: center;
  font-style: italic;
}

.testimonials .item .name {
  font-family: Noto serif,serif;
  font-style: normal;
  color: #4c4740;
  font-weight: 600;
}

.fb-widget {
  max-width: 500px;
  margin: 0 auto 1.875rem auto;
}

.subscribe {
  padding: 1.875rem 0;
}

.subscribe p.h4 {
  color: #555351;
}

.subscribe .desc {
  max-width: 21.875rem;
  margin-bottom: 0;
}

.subscribe .head {
  display: flex;
}

.subscribe .head .img {
  padding-right: 1.25rem;
}

.subscribe input[type=email].form-control {
  box-shadow: none;
  background: #F0EEE9;
}

.subscribe-popup {
  width: 100%;
  max-width: 850px;
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  -moz-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  -o-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  z-index: 50;
  margin-top: 10px;
  transition: 0.5s all;
}

.subscribe-popup.active {
  margin-top: 0;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.subscribe-popup .subscribe {
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.subscribe-popup .close {
  font-size: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7a6e60;
  color: #fff;
  text-decoration: none;
}

.subscribe-popup .close:hover {
  background: #cbaf65;
}

.subscribe-trigger {
  position: fixed;
  z-index: 50;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  background: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  transition: 0.3s all;
}

.subscribe-trigger:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.subscribe-trigger.active {
  display: flex;
}

.search-results {
  max-width: 800px;
  margin: 0 auto 3.75rem auto;
}

.search-results h2 {
  font-family: Noto serif,serif;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3em;
  margin-bottom: 1.875rem;
}

.search-results .item-list {
  margin-bottom: 2.5rem;
}

.search-results .item-inner {
  display: flex;
}

.search-results .item {
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
}

.search-results .item .img {
  width: 100%;
  max-width: 150px;
  flex: 0 0 150px;
}

.search-results .item .text {
  padding-left: 1.25rem;
}

.search-results .item .text h3 {
  font-size: 1.25rem;
}

.search-results .item .text p:last-child {
  margin-bottom: 0;
}

.search-results .item.no-image .text {
  padding-left: 0;
}

.search-results .foot {
  text-align: center;
}

.search-expression {
  font-weight: 600;
  padding: 15px 0 0;
  color: #cbaf65;
}

body .easy-autocomplete {
  display: flex;
  width: auto !important;
  flex-grow: 1;
  flex-wrap: wrap;
  position: static !important;
}

body .easy-autocomplete input.text {
  flex: 0 0 100%;
}

body .easy-autocomplete-container {
  top: 5rem;
}

body .easy-autocomplete-container.active ul {
  display: flex !important;
}

body .easy-autocomplete-container ul {
  flex-wrap: wrap;
  margin: 0;
  padding: 0.9375rem;
  border: none;
  border-top: 3px solid #cbaf65;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

body .easy-autocomplete-container ul:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -0.9375rem;
  margin-left: -0.9375rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.9375rem 0.9375rem 0.9375rem;
  border-color: transparent transparent #cbaf65 transparent;
}

body .easy-autocomplete-container ul li {
  display: flex;
  flex: 0 0 50%;
  width: 100%;
  max-width: 50%;
  margin: 0 0 1.25rem 0;
  padding: 0;
  border: none;
}

body .easy-autocomplete-container ul li.selected {
  background: none;
}

body .easy-autocomplete-container .eac-category {
  font-family: Noto serif,serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3em;
  font-style: normal !important;
  flex: 0 0 100%;
  padding: 20px 0;
  color: #cbaf65;
  border: none !important;
}

body .easy-autocomplete-container .eac-category:first-of-type {
  padding-top: 0;
}

body .easy-autocomplete-container .eac-item {
  display: flex;
  padding: 0 15px 0 0;
  width: 100%;
}

body .easy-autocomplete-container .eac-item .img {
  flex: 0 0 60px;
  max-width: 60px;
  max-height: inherit !important;
  height: auto;
  margin-right: 10px;
}

body .easy-autocomplete-container .eac-item .img img {
  max-width: 100%;
  max-height: inherit !important;
}

body .header .easy-autocomplete {
  display: block;
}

body .header .easy-autocomplete-container {
  top: 130px;
}

body .header .easy-autocomplete-container ul:before {
  left: auto;
  right: 70px;
}

.svg-map {
  max-width: 550px;
  margin: 0 auto 1.25rem auto;
  position: relative;
}

.svg-map svg {
  pointer-events: auto;
  max-width: 100%;
  height: auto;
}

.svg-map .base {
  display: block;
}

.svg-map .area {
  fill: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.svg-map .area:hover {
  fill: #e5e1d9;
}

.svg-map .area.active {
  fill: #efece7;
}

.svg-map .labels .item {
  font-size: 0.6875rem;
  font-family: Noto serif,serif;
  line-height: 1.3em;
  @incude rem(max-width, 90px);
  pointer-events: none;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.svg-map .labels .item .num {
  font-weight: 600;
}

.svg-map .labels .item.jihomoravsky-kraj {
  left: 68%;
  top: 78%;
}

.svg-map .labels .item.zlinsky-kraj {
  left: 83%;
  top: 74%;
}

.svg-map .labels .item.olomoucky-kraj {
  left: 77%;
  top: 57%;
}

.svg-map .labels .item.pardubicky-kraj {
  left: 60%;
  top: 46%;
}

.svg-map .labels .item.plzensky-kraj {
  left: 15%;
  top: 57%;
}

.svg-map .labels .item.jihocesky-kraj {
  left: 34%;
  top: 79%;
}

.svg-map .labels .item.moravskoslezsky-kraj {
  left: 89%;
  top: 52%;
}

.svg-map .labels .item.kralovehradecky-kraj {
  left: 54%;
  top: 28%;
}

.svg-map .labels .item.stredocesky-kraj {
  left: 36%;
  top: 50%;
}

.svg-map .labels .item.ustecky-kraj {
  left: 25%;
  top: 22%;
}

.svg-map .labels .item.karlovarsky-kraj {
  left: 10%;
  top: 36%;
}

.svg-map .labels .item.liberecky-kraj {
  left: 42%;
  top: 14%;
}

.svg-map .labels .item.kraj-vysocina {
  left: 53%;
  top: 65%;
}

.svg-map .labels .item.praha {
  left: 35%;
  top: 39%;
}

.map-wrapper {
  position: relative;
  background: #e3e3e3;
}

.map-wrapper .map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.map-content {
  height: 500px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.map-content .box {
  text-align: left;
  position: relative;
  z-index: 20;
  width: 100%;
  padding: 0;
  max-width: 300px;
  display: inline-block;
  vertical-align: top;
}

.map-content .box .box-text {
  padding: 1.875rem;
  background: #fff;
}

.map-content .box .box-text ul:last-child {
  margin-bottom: 0;
}

.map {
  width: 100%;
  height: 650px;
}

.map .markers {
  display: none;
}

.map img {
  max-width: inherit;
}

.popup {
  font-size: 15px;
  background: #fff;
  width: 18.75rem;
}

.popup .close {
  font-size: 20px;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  background: #cbaf65;
  color: #fff;
  text-decoration: none;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.popup .text {
  @ínclude rem(padding, 30px);
}

.popup .text p {
  margin-bottom: 10px;
}

.popup .text p:last-child {
  margin-bottom: 0;
}

.popup h3 {
  font-size: 17px;
  margin: 0 0 0.625rem 0;
}

.popup ul {
  margin: 0;
}

.popup ul li {
  padding: 0;
  background: none;
}

.popup a {
  text-decoration: underline;
}

.popup a:hover {
  text-decoration: none;
}

/*
.gm-style .gm-style-iw {
	background-color: #3C61AD !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	max-width: 300px;
	min-height: 120px !important;
	padding: 0;
	display: block !important;
}

.js-info-bubble-close {
	position: absolute;
	right: 0 !important;
	top: 0 !important;
	z-index: 10;
	display: block;
	width: 30px;
	height: 30px;
	opacity: 0;
}
*/
.smap-wrapper {
  height: 650px;
  position: relative;
  margin-bottom: 3.75rem;
}

.smap-wrapper .box {
  font-size: 0.9375rem;
  line-height: 1.6em;
  position: absolute;
  left: 0;
  bottom: 1.25rem;
  z-index: 50;
  background: #fff;
  padding: 1.875rem;
  min-width: 260px;
}

.smap-wrapper .box h3 {
  font-size: 1.5rem;
  line-height: 1.2em;
  color: #236276;
}

.smap-wrapper .box ul {
  margin: 0;
}

.smap-wrapper .box ul.contact-list li {
  padding-left: 1.875rem;
  margin: 0;
  position: relative;
}

.smap-wrapper .box ul.contact-list li .icon {
  position: absolute;
  left: 0;
  top: 0.3125rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #3aaa35;
}

.smap {
  width: 100%;
  height: 100% !important;
  position: absolute !important;
  left: 0;
  top: 0;
}

.smap img {
  max-width: inherit;
}

body .smap .leaflet-popup-content-wrapper, body .smap .leaflet-popup-tip {
  padding: 0;
}

body .smap .leaflet-popup-content, body .smap .card {
  padding: 0;
  background: none;
  box-shadow: none;
  overflow: visible !important;
  margin: 0 !important;
}

body .smap .leaflet-popup-content img, body .smap .card img {
  max-width: 100%;
}

body .smap .leaflet-popup-content .text, body .smap .card .text {
  padding: 1.25rem;
}

body .smap .leaflet-popup-content .text .title, body .smap .card .text .title {
  font-size: 19px;
  font-family: Noto serif, serif;
  color: #7a6e60;
  margin: 0 0 0.3125rem 0;
}

body .smap .leaflet-popup-content .text .title a, body .smap .card .text .title a {
  color: #7a6e60;
}

body .smap .leaflet-popup-content .text .desc, body .smap .card .text .desc {
  font-size: 0.875rem;
  line-height: 1.5em;
}

body .smap .leaflet-popup-content .text .desc p:last-child, body .smap .card .text .desc p:last-child {
  margin-bottom: 0;
}

body .smap .leaflet-popup-content .text .cat-list, body .smap .card .text .cat-list {
  padding-top: 0.625rem;
}

body .smap .leaflet-popup-content .tail, body .smap .card .tail {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: -10px;
  width: 0;
  height: 0;
  visibility: visible;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
}

body .smap .leaflet-popup-content .opening-info, body .smap .card .opening-info {
  margin: 0.625rem 0 0 0;
}

body .smap .leaflet-popup-content .card-header, body .smap .card-header {
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}

body .smap .close, body .smap .leaflet-popup-close-button {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #555351;
  border-radius: 50%;
}

body .smap .close span, body .smap .leaflet-popup-close-button span {
  display: none;
}

body .smap .close:after, body .smap .leaflet-popup-close-button:after {
  font-family: mapy;
  content: "c";
  font-size: 20px;
  color: #fff;
}

body .smap .close:hover, body .smap .leaflet-popup-close-button:hover {
  color: #fff;
  background: #4c4740;
}

body .smap .close:hover:after, body .smap .leaflet-popup-close-button:hover:after {
  color: #fff;
}

/************************************************************************************
FOOTER
*************************************************************************************/
.footer {
  background: #fff;
  padding: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.footer .f_1 {
  border-top: 1px solid #eaeaea;
  padding-top: 3.125rem;
}

.footer .f_2 .inner {
  border-top: 1px solid #C7C7C7;
  display: flex;
  justify-content: center;
  padding: 1.875rem 0;
}

.footer .logo-foot {
  display: block;
  margin: 0 auto 3.125rem;
}

.footer h3 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3em;
  color: #7d7b79;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.footer ul {
  margin: 0 0 2.5rem 0;
}

.footer ul.unstyled li {
  padding: 0;
}

.footer ul.unstyled li:after {
  content: none;
}

.footer ul.contact-list li {
  padding: 0 0 0 1.875rem;
}

.footer ul.contact-list li .icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #7a6e60;
}

.footer ul.contact-list li:after {
  content: none;
}

.footer a {
  color: rgba(125, 123, 121, 0.83);
  text-decoration: none;
}

.footer a:hover {
  color: #7d7b79;
  text-decoration: underline;
}

.footer .social-nav ul li {
  padding: 0 5px;
}

.footer .social-nav ul li:after {
  content: none;
}

.footer .social-nav ul li a {
  border-color: #7a6e60;
  color: #7a6e60;
}

.footer .social-nav ul li a:hover {
  background: #7a6e60;
  color: #fff;
}

.orwin {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  transition: all 0.3s;
  opacity: .5;
}

.orwin:hover {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  -webkit-filter: grayscale(0%);
  opacity: 1;
}

.orwin .text {
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -80000px;
  display: block;
}

/************************************************************************************
MEDIA QUERIES
*************************************************************************************/
/*========================= Viewport width 1199px and lower =========================*/
@media screen and (max-width: 1199px) {
  .events {
    position: static;
    padding: 0;
  }
  .events .item .text .actions {
    position: static;
    padding: 0;
  }
  .events .item .text .inner {
    padding: 0 0 0 1.875rem;
  }
  .events .item-inner {
    padding: 1.875rem;
  }
  .header .search {
    max-width: 9.0625rem;
  }
  .main-nav ul {
    margin: 0 -0.375rem;
  }
  .main-nav ul li {
    padding: 0 0.375rem;
  }
  .main-nav ul li a {
    font-size: 0.8125rem;
  }
  .social-nav ul {
    margin: 0 -0.125rem;
  }
  .social-nav ul li {
    padding: 0 0.125rem;
  }
}

/*========================= Viewport width 991px and lower =========================*/
@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }
  h1, .h1 {
    font-size: 2.5rem;
  }
  .main-nav {
    display: none;
  }
  .nav-btn {
    display: block;
  }
  .events .item .img {
    width: 16.875rem;
  }
  .events .item .text {
    padding-left: 16.875rem;
  }
  .posts .column {
    max-width: 560px;
    margin: 0 auto;
  }
  .posts .item .img {
    margin-bottom: 10px;
    max-width: 17.5rem;
    flex: 0 0 17.5rem;
  }
  .posts .item .text {
    max-width: 100%;
    flex: auto;
  }
  .posts .item:nth-child(4n) .img, .posts .item:nth-child(4n+3) .img {
    order: 0;
  }
  .posts .item:nth-child(4n) .text, .posts .item:nth-child(4n+3) .text {
    order: 1;
  }
  .posts .item:nth-child(4n) .text .inner, .posts .item:nth-child(4n+3) .text .inner {
    padding-right: 0;
    padding-left: 1.25rem;
  }
  .fb-widget {
    margin-bottom: 5rem;
  }
  .gallery .item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .tips-side .item h3 {
    font-size: 1.25rem;
  }
  form.form-filter-events .form-inner {
    flex-wrap: wrap;
  }
  form.form-filter-events .btn-set {
    width: 100%;
    padding-top: 10px;
  }
  body .fancybox-thumbs {
    display: none !important;
  }
}

/*========================= Viewport width 767px and lower =========================*/
@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
  .header .inner {
    height: 6.25rem;
  }
  .teaser-content {
    height: 550px;
  }
  .teaser-content .desc {
    text-align: center;
    position: relative;
    padding-bottom: 0;
    padding-top: 1.875rem;
  }
  .teaser-slider li.panel {
    background-size: cover !important;
  }
  .events .item .img {
    width: 100%;
    float: none;
    display: block;
    margin-bottom: 1.25rem;
  }
  .events .item .text {
    padding-left: 0;
  }
  .events .item .text .inner {
    padding: 0;
  }
  .posts .item .img {
    max-width: 200px;
    flex: 0 0 200px;
  }
  .properties .item-list {
    flex-wrap: wrap;
  }
  .properties .item {
    font-size: 1rem;
    flex: 0 0 33.333333%;
    max-width: 33.33333%;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .text-image {
    margin-bottom: 2.5rem;
  }
  .text-image .img {
    max-width: 100%;
    flex: 0 0 100%;
    order: 0;
    margin-bottom: 2.5rem;
  }
  .text-image .text {
    max-width: 100%;
    flex: 0 0 100%;
    order: 1;
  }
  .detail-info .opening table td.month {
    width: auto;
  }
  .gallery .item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .subscribe .head {
    margin-bottom: 1.875rem;
  }
  form.form-filter-cr fieldset {
    padding-left: 0;
  }
  form.form-filter-cr fieldset legend {
    position: static;
    margin-bottom: 1.25rem;
  }
}

/*========================= Viewport width 575px and lower =========================*/
@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }
  body {
    font-size: 15px;
    line-height: 1.8em;
  }
  h1, .h1 {
    font-size: 2.1875rem;
  }
  h2, .h2 {
    font-size: 1.875rem;
  }
  .header .social-nav {
    display: none;
  }
  .tab-nav li a {
    padding: 0.625rem 0.9375rem;
  }
  .tab-nav li a .txt {
    padding: 0;
  }
  .tab-nav li a .icon {
    display: none;
  }
  .teaser .slider-nav {
    bottom: 20px;
  }
  .teaser-slider li.panel {
    background-size: cover !important;
  }
  .modal-instagram .img {
    margin-right: 0;
  }
  .modal-instagram .text {
    position: static;
    width: 100%;
  }
  .modal-campaign .modal-content {
    padding: 1.25rem;
  }
  .search-results .item .img {
    flex: 0 0 100px;
  }
  .opening table td.month,
  .opening table td.head {
    width: auto;
  }
  .event-archive .item-inner {
    flex-wrap: wrap;
  }
  .event-archive .item .img {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1.25rem;
  }
  .event-archive .item .text {
    padding-left: 0;
  }
  .posts .item .img {
    max-width: 7.5rem;
    flex: 0 0 7.5rem;
  }
  .tips-side .item-list {
    margin: 0 -5px;
  }
  .tips-side .item {
    padding: 0 5px;
  }
  .main-cats {
    margin-bottom: -2.5rem;
  }
  .main-cats .item-inner {
    display: block;
  }
  .main-cats .item .img {
    position: relative;
    display: block;
    margin: 0 auto;
  }
  .main-cats .item .text {
    padding: 0;
    text-align: center;
    max-width: 100%;
  }
  .gallery .item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .subscribe-popup .subscribe {
    padding-left: 0;
    padding-right: 0;
  }
  .subscribe-trigger {
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;
  }
  .box {
    padding: 15px;
  }
  body .easy-autocomplete-container {
    top: 3.75rem;
  }
  body .easy-autocomplete-container ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  body .header .easy-autocomplete-container {
    top: 80px;
  }
}

/*========================= Viewport width 420px and lower =========================*/
@media screen and (max-width: 420px) {
  /*.gallery .item { width: 50%; }

  .sights .item .text { padding: 20px 15px; }

  .events .item-inner { padding: 15px; }

  .main-cats .item { border: none; }*/
}

/*# sourceMappingURL=all-scss.css.map */