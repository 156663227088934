/**
 * Colors
 */
$black: #2b343b;
$white: #fff;
$blue: #55c5d1;
$blueLight: #f3fbfc;
$blueDark: #4c6579;
$purple: #ca9bd2;
$purpleLight: #f7f0f8;
$golden1: #ADA295;

/**
 * Font
 */
$fontFamily: Noto serif, serif;
$fontFamilyRoboto: Roboto, sans-serif;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;

@font-face {
  font-family: 'space_groteskregular';
  src: url('../font/spacegrotesk-regular-webfont.woff2') format('woff2'),
       url('../font/spacegrotesk-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_groteskmedium';
  src: url('../font/spacegrotesk-medium-webfont.woff2') format('woff2'),
       url('../font/spacegrotesk-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_grotesksemibold';
  src: url('../font/spacegrotesk-semibold-webfont.woff2') format('woff2'),
       url('../font/spacegrotesk-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'space_groteskbold';
  src: url('../font/spacegrotesk-bold-webfont.woff2') format('woff2'),
       url('../font/spacegrotesk-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}