﻿/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  .events {
    position: static;
    padding: 0;

    .item {
      .text {
        .actions {
          position: static;
          padding: 0;
        }

        .inner {
          @include rem(padding, 0 0 0 30px);
        }
      }
    }
    
    .item-inner {
      @include rem(padding, 30px);
    }    
  }

  .header {
    .search {
      @include rem(max-width, 145px);
    }
  }

  .main-nav {
    ul {
      @include rem(margin, 0 -6px);

      li {
        @include rem(padding, 0 6px);

        a {
          @include rem(font-size, 13px);
        }
      }
    }
  }

  .social-nav {
    ul {
      @include rem(margin, 0 -2px);

      li {
        @include rem(padding, 0 2px);
      }
    }
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  h1, .h1 {
    @include rem(font-size, 40px);
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .events {
    .item {
      .img {
        @include rem(width, 270px);
      }

      .text {
        @include rem(padding-left, 270px);        
      }
    }
  }

  .posts {
    .column {
      max-width: 560px;
      margin: 0 auto;
    }

    .item {
      .img {
        margin-bottom: 10px;
        @include rem(max-width, 280px);
        @include rem(flex, 0 0 280px);
      }

      .text {
        max-width: 100%;
        flex: auto;
      }

      &:nth-child(4n),
      &:nth-child(4n+3) {
        .img {
          order: 0;
        }

        .text {
          order: 1;

          .inner {
            padding-right: 0;
            @include rem(padding-left, 20px);
          }
        }
      }
    }
  }

  .fb-widget {
    @include rem(margin-bottom, 80px);
  }

  .gallery {
    .item {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .tips-side {
    .item {
      h3 {
        @include rem(font-size, 20px);
      }
    }
  }

  form.form-filter-events {
    .form-inner {
      flex-wrap: wrap;
    }

    .btn-set {
      width: 100%;
      padding-top: 10px;
    }
  }

  body .fancybox-thumbs {
    display: none !important;
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  .header {
    .inner {
      @include rem(height, 100px);
    }
  }

  .teaser-content {
    height: 550px;

    .desc {
      text-align: center;
      position: relative;
      padding-bottom: 0;
      @include rem(padding-top, 30px);
    }
  }

  .teaser-slider {
    li.panel {
      background-size: cover !important;
    }
  }

  .events {
    .item {
      .img {
        width: 100%;
        float: none;
        display: block;
        @include rem(margin-bottom, 20px);
      }

      .text {
        padding-left: 0;

        .inner {
          padding: 0;
        }
      }
    }
  }

  .posts {
    .item {
      .img {
        max-width: 200px;
        flex: 0 0 200px;
      }
    }
  }

  .properties {
    .item-list {
      flex-wrap: wrap;
    }

    .item {
      @include rem(font-size, 16px);
      flex: 0 0 33.333333%;
      max-width: 33.33333%;
      @include rem(padding-top, 15px);
      @include rem(padding-bottom, 15px);
    }
  }

  .text-image {
    @include rem(margin-bottom, 40px);

    .img {
      max-width: 100%;
      flex: 0 0 100%;
      order: 0;
      @include rem(margin-bottom, 40px);
    }

    .text {
      max-width: 100%;
      flex: 0 0 100%;
      order: 1;
    }
  }  

  .detail-info .opening table td.month {
    width: auto;
  }

  .gallery {
    .item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .subscribe {
    .head {
      @include rem(margin-bottom, 30px);
    }
  }

  form.form-filter-cr {
    fieldset {
      padding-left: 0;

      legend {
        position: static;
        @include rem(margin-bottom, 20px);
      }
    }
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  body {
    font-size: 15px;
    line-height: 1.8em;
  }

  h1, .h1 {
    @include rem(font-size, 35px);
  }

  h2, .h2 {
    @include rem(font-size, 30px);
  }

  .header {
    .social-nav {
      display: none;
    }
  }

  .tab-nav {
    li {
      a {
        @include rem(padding, 10px 15px);

        .txt {
          padding: 0;
        }

        .icon {
          display: none;
        }
      }
    }
  }

  .teaser {
    .slider-nav {
      bottom: 20px;
    }
  }

  .teaser-slider {
    li.panel {
      background-size: cover !important;
    }
  }

  .modal-instagram {
    .img {
      margin-right: 0;
    }

    .text {
      position: static;
      width: 100%;
    }
  }

  .modal-campaign {
    .modal-content {
      @include rem(padding, 20px);
    }
  }

  .search-results .item .img {
    flex: 0 0 100px;
  }

  .opening {
    table td.month,
    table td.head {
      width: auto;
    }
  }  

  .event-archive {
    .item-inner {
      flex-wrap: wrap;
    }

    .item {
      .img {
        flex: 0 0 100%;
        max-width: 100%;
        @include rem(margin-bottom, 20px);
      }

      .text {
        padding-left: 0;
      }
    }
  }  

  .posts .item .img {
    @include rem(max-width, 120px);
    @include rem(flex, 0 0 120px);
  }

  .tips-side {
    .item-list {
      margin: 0 -5px;
    }
    .item {
      padding: 0 5px;
    }
  }

  .main-cats {
    @include rem(margin-bottom, -40px);

    .item-inner {
      display: block;
    }

    .item {
      .img {
        position: relative;
        display: block;
        margin: 0 auto;

        .icon {
          //@include rem(width, 45px);
          //@include rem(height, 45px);
        }
      }      

      .text {
        padding: 0;
        text-align: center;
        max-width: 100%;
      }
    }
  }

  .gallery {
    .item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .subscribe-popup {
    .subscribe {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .subscribe-trigger {
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;
  }

  .box {
    padding: 15px;
  }

  body .easy-autocomplete-container {
    @include rem(top, 60px);

    ul li {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  body .header .easy-autocomplete-container {
    top: 80px;
  }
}

/*========================= Viewport width 420px and lower =========================*/

@media screen and (max-width: 420px) {
  /*.gallery .item { width: 50%; }

  .sights .item .text { padding: 20px 15px; }

  .events .item-inner { padding: 15px; }

  .main-cats .item { border: none; }*/
}