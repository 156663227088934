﻿/*
 *
 * OBJEVUJ PAMÁTKY
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */

/************************************************************************************
RESET
*************************************************************************************/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
img {
  display: block;
}

input[type='radio'] {
  vertical-align: text-bottom;
}
input[type='checkbox'] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

label,
input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
  overflow: visible;
}

em,
i {
  font-weight: inherit;
}

/************************************************************************************
GENERAL
*************************************************************************************/

html {
  height: 100%;
}

body {
  font-family: Roboto, sans-serif;
  @include rem(font-size, 16px);
  line-height: 1.625em;
  color: #7d7b79;
  background: #efeeec;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

/* Box sizing */

*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* Anchors */

a {
  color: #7a6e60;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/* Heading */

h1,
.h1 {
  font-family: Noto serif, serif;
  @include rem(font-size, 48px);
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 30px 0);

  &.small {
    @include rem(font-size, 36px);
  }
}

h2,
.h2 {
  font-family: Noto serif, serif;
  @include rem(font-size, 36px);
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 20px 0);

  &.small {
    @include rem(font-size, 24px);
  }
}

h3,
.h3 {
  font-family: Noto serif, serif;
  @include rem(font-size, 24px);
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 10px 0);

  &.small {
    @include rem(font-size, 20px);
  }
}

h4,
.h4 {
  font-family: Noto serif, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 10px 0);
}

h5,
.h5 {
  font-family: Noto serif, serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 10px 0);
}

h6,
.h6 {
  font-family: Noto serif, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3em;
  color: #cbaf65;
  @include rem(margin, 0 0 10px 0);
}

/* Paragraphs */

p {
  @include rem(margin, 0 0 20px 0);
}

address {
  font-style: normal;
}

cite {
  font-style: normal;
}

/* Lists */

ul,
ol {
  list-style: none;
  @include rem(margin, 0 0 20px 0);
}

ul {
  li {
    @include rem(padding, 0 0 0 20px);
    @include rem(margin, 0 0 5px 0);
  }
}

ul,
.footer ul:not(.unstyled) {
  li {
    @include rem(padding, 0 0 0 40px);
    @include rem(margin, 0 0 10px 0);
    position: relative;
    &:before {
      content: '';
      @include rem(width, 25px);
      height: 2px;
      background: $golden1;
      position: absolute;
      @include rem(top, 12px);
      left: 0;
    }
  }
}
.footer ul:not(.unstyled) li:before {
  height: 1px;
}

ul.unstyled li {
  padding: 0;
  background: none;
}

ol {
  counter-reset: counter;
  margin-left: 0;
  *margin-left: 54px;

  li {
    margin: 0 0 10px 0;
    padding: 0;
    @include rem(padding, 0 0 0 40px);
    *list-style-type: decimal;
    counter-increment: counter;
    position: relative;

    &:before {
      color: $golden1;
      content: counter(counter) '. ';
      position: absolute;
      left: 0;
      @include rem(top, -2px);
      @include rem(font-size, 20px);
      font-family: $fontFamily;
      font-weight: bold;
    }
  }
}

dl {
  margin: 0 0 20px 0;
}

dt {
  font-weight: bold;
}

/* Forms */

form {
  display: block;
}

fieldset {
  border: none;
}

label {
  font-weight: bold;
}

input,
select,
button {
  vertical-align: top;
}

/* Various */

sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

p.small {
  font-size: 70%;
}

p.big {
  font-size: 130%;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */

  .alignnone {
    margin-bottom: 20px;
  }

  .alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #d5d5d6;
    position: relative;
    top: 7px;
  }

  .alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 1px solid #d5d5d6;
    position: relative;
    top: 7px;
  }

  .aligncenter {
    margin: 0 auto 20px auto;
    padding-top: 10px;
  }

  .border {
    border: 1px solid #d5d5d6;
  }
}

.bold {
  font-weight: bold;
}

.nowrap,
.nobr {
  white-space: nowrap !important;
}

/* Alignment */

.v-top {
  vertical-align: top;
}

.v-middle {
  vertical-align: middle;
}

.v-bottom {
  vertical-align: bottom;
}

.l {
  float: left;
}

.r {
  float: right;
}

.a-l {
  text-align: left;
}

.a-r {
  text-align: right;
}

.a-c {
  text-align: center;
}

/* Content */

.content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0px 0 30px #ada29580;
}

/* Block */

.block {
  @include rem(padding, 60px 0);
  &.narrow {
    @include rem(padding, 30px 0);
  }

  &.bb {
    border-bottom: 1px solid #eaeaea;
  }

  &.alt {
    background: #f9f8f6;
  }

  &.alt-2 {
    background: #42403d;
    color: #fff;
  }

  &.alt-2 h2 {
    color: #fff;
  }

  &.alt-2 .section-head.alt h2 {
    color: #fff;
  }

  &.alt-2 .btn:hover {
    border-color: #fff;
  }

  &.alpha {
    padding-top: 0;
  }

  &.omega {
    padding-bottom: 0;
  }

  &.bg-white {
    background-color: white;
  }
}

/* Section head */

.section-head {
  text-align: center;
  @include rem(margin, 0 auto 20px auto);
  max-width: 750px;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  &.alt {
    h1,
    .h1,
    h2,
    .h2 {
      font-weight: 400;
      color: #4c4740;
    }
  }
}

.intro {
  text-align: center;
  max-width: 600px;
  @include rem(margin, 0 auto 30px auto);
  @include rem(font-size, 18px);
  font-family: $fontFamily;

  p:last-child {
    margin-bottom: 0;
  }
}

.narrow-col {
  max-width: 800px;
  margin: 0 auto;
}

/* Pager */

.pager {
  @include rem(margin, 0 0 30px 0);
  text-align: center;
  position: relative;

  li {
    display: inline-block;
    vertical-align: top;
    @include rem(padding, 0 2px);
    margin: 0;
    background: none;

    a {
      @include rem(font-size, 15px);
      font-weight: 600;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include rem(width, 30px);
      @include rem(height, 30px);
      border: 2px solid #97928e;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      color: #97928e;
      @include transition(all, 0.3);
    }

    a:hover {
      border-color: #7a6e60;
      background: #7a6e60;
      color: #fff;
    }

    &.active a {
      background: #7a6e60;
      border-color: #7a6e60;
      color: #fff;
      text-decoration: none;
    }
  }

  .prev {
    position: absolute;
    left: 0;
    top: 0;
  }

  .next {
    position: absolute;
    right: 0;
    top: 0;
  }

  .counter {
    display: none;
  }
}

/* Cookie Notice */

.cookie-notice {
  font-size: 12px;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 100%;
  @include rem(padding, 10px 15px);
  background: rgba(0, 0, 0, 0.9);
  color: #ccc;
  text-align: center;
}

.cookie-button {
  display: inline-block;
  padding: 2px 10px;
  margin-left: 25px;
  background: #7a6e60;
  color: #fff;
  text-align: center;

  &:hover {
    background: #cbaf65;
    color: #fff;
  }
}

.cookie-test {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  @include rem(width, 150px);
  @include rem(padding, 25px 15px);
  background: rgba(50, 50, 50, 0.95);
  color: #ccc;
  text-align: center;
}

/* Viewer */

.viewer {
  width: 100%;
}

/* Box */

.box {
  @include rem(padding, 30px);
  background: #f4f3f1;
}

/* Responsive iframe */

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*================================= Various Content =================================*/

.last {
  margin-bottom: 0 !important;
}

ul.contact-list {
  li {
    line-height: 24px;
    @include rem(padding, 0 0 0 30px);
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;

    .icon {
      position: absolute;
      left: 0;
      @include rem(top, 2px);
      color: #7a6e60;
    }

    a {
      color: rgba(125, 123, 121, 0.83);
      text-decoration: none;
    }

    a:hover {
      color: rgba(125, 123, 121, 1);
      text-decoration: underline;
    }
  }
}

/* Buttons */

.btn {
  font-family: Noto serif, serif;
  @include rem(font-size, 18px);
  font-weight: 400;
  line-height: 1.3em;
  @include rem(padding, 0 30px);
  @include rem(height, 50px);
  border: 2px solid #7a6e60;
  background: #7a6e60;
  color: #fff;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  @include rem(border-radius, 30px);
  @include transition(all, 0.3);

  &:hover {
    background-color: #cbaf65;
    border-color: #cbaf65;
  }

  &.line {
    background: none;
    color: #7a6e60;

    &:hover {
      background: #cbaf65;
      border-color: #cbaf65;
      color: #fff;
    }
  }

  &.small {
    font-family: Noto serif, serif;
    @include rem(font-size, 15px);
    font-weight: 400;
    line-height: 1.3em;
    @include rem(padding, 0 20px);
    @include rem(height, 40px);
  }
}

/* Vertical align */

.va-wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.va-m {
  position: relative;
  top: 50%;
  @include translate(0, -50%);
}

.va-t {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.va-b {
  display: table-cell;
  vertical-align: bottom;
  height: 100%;
}

/* Icons */

svg {
  pointer-events: none;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  fill: currentColor;
}

/* Hidden content */

.sh-content {
  display: none;
}

/*================================= Columns =================================*/

.ib {
  font-family: Arial, sans-serif;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
.ib > * {
  font-family: Roboto, sans-serif;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  letter-spacing: normal;
  word-spacing: normal;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
  margin: 0;

  h2 {
    @include rem(font-size, 32px);
    line-height: 1.2em;
    @include rem(margin, 40px 0 20px 0);
    color: #4c4740;
  }

  h3 {
    @include rem(font-size, 24px);
    line-height: 1.2em;
    @include rem(margin, 40px 0 20px 0);
    color: #4c4740;
  }

  ul {
    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        background: #000;
      }
    }
  }
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
  width: 100%;
  @include rem(margin, 0 0 30px 0);

  table {
    width: 100%;
    @include rem(margin, 0 0 30px 0);

    th {
      font-weight: normal;
      border-bottom: 0 solid #eaeaea;
      @include rem(padding, 15px 30px);
      background: #cbaf65;
      color: #fff;
      text-align: left;
    }

    td {
      border-bottom: 1px solid #eaeaea;
      @include rem(padding, 15px 30px);
    }
  }
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std,
.form.std {
  label {
    font-family: Noto serif, serif;
    @include rem(font-size, 15px);
    font-weight: 400;
    line-height: 1.3em;
    color: #645d52;
    @include rem(margin, 0 0 10px 0);
    display: block;
  }

  input.text {
    font-family: Noto serif, serif;
    @include rem(font-size, 16px);
    font-weight: 400;
    line-height: 1.3em;
    color: #7d7b79;
    border: 2px solid #cbc8c6;
    @include rem(height, 50px);
    @include rem(padding, 0 15px);
    width: 100%;

    &[required='required'] {
      background-image: url('../img/req.png');
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  textarea {
    line-height: 1.3em;
    resize: vertical;
    @include rem(padding, 10px 15px);
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 150px;
    min-height: 150px;
    max-height: 300px;
    display: block;
  }

  select {
    min-width: 120px;
  }

  option {
    font-family: Noto serif, serif;
    @include rem(font-size, 16px);
    font-weight: 400;
    line-height: 1.3em;
    @include rem(padding, 2px 15px);
  }

  .cell {
    @include rem(margin-bottom, 20px);
    position: relative;
  }

  .toggle {
    position: relative;
    @include rem(padding, 0 0 0 30px);

    label {
      display: inline-block;
      vertical-align: top;
      margin: 0;
    }

    .note {
      @include rem(font-size, 16px);
      max-width: 100%;
    }
  }

  .radio,
  .checker {
    position: absolute;
    left: 0;
    @include rem(top, 0);
  }
}

/* Form filter */

form.form-filter-cr {
  fieldset {
    position: relative;
    @include rem(margin-bottom, 20px);

    .head {
      @include rem(margin-bottom, 20px);
      line-height: 1.3em;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      legend {
        font-family: Noto serif, serif;
        @include rem(font-size, 18px);
        font-weight: 600;
        line-height: 1.3em;
        display: block;
        @include rem(margin-bottom, 20px);
        flex-grow: 1;
        flex: 0 0 100%;
      }
    }
  }

  .cancel {
    display: none;

    &.active {
      display: block;
    }
  }

  .cell {
    @include rem(margin-bottom, 10px);
  }

  .toggle {
    @include rem(padding-left, 30px);
  }

  .regions {
    display: none;
  }

  .foot {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancel {
      @include rem(margin-left, 20px);
    }
  }
}

/* Form events */

form.form-filter-events {
  @include rem(padding, 40px 0);

  .form-inner {
    display: flex;
  }

  .inputs {
    flex-grow: 1;
  }

  label {
    height: 20px;
  }

  .cell {
    @include rem(margin-bottom, 10px);
  }

  .btn-set {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1em;
    padding-left: 20px;
  }

  .btn {
    margin-left: 20px;
  }

  .cancel {
    white-space: nowrap;
  }
}

/* Form search */

form.form-search {
  input.text {
    font-weight: 600;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 30px;
    @include rem(height, 60px);
    @include rem(line-height, 20px);
    @include rem(padding, 0 20px);
    letter-spacing: 0.05em;
    background: none;

    &:-moz-placeholder {
      color: #fff;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: #fff;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #fff;
    }

    &::-webkit-input-placeholder {
      color: #fff;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    @include rem(width, 60px);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    text-align: center;
    @include rem(line-height, 60px);
    background: none;
    border: none;
  }
}

.header {
  form.form-search {
    input {
      @include rem(height, 50px);
      @include rem(padding-right, 40px);
      font-family: $fontFamilyRoboto;
      border: 0;
      background: #ffffff55;
      color: white;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1.12px;

      &:-moz-placeholder {
        color: white;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: white;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: white;
      }
      &::-webkit-input-placeholder {
        color: white;
      }
    }

    .cell {
      position: static;
      margin: 0;
      display: flex;

      &.loading {
        .btn {
          display: none;
        }
      }

      &.loading {
        .loader {
          @include rem(width, 50px);
          @include rem(height, 50px);
          @include rem(margin-left, -50px);
          @include rem(flex, 0 0 50px);
          display: flex;
          justify-content: center;
          position: static;
        }
      }
    }

    .btn {
      @include rem(width, 50px);
      @include rem(height, 50px);
      @include rem(margin-left, -50px);
      @include rem(flex, 0 0 50px);
      display: flex;
      justify-content: center;
      position: static;
    }

    .loader {
      display: none;
    }
  }

  &.alt {
    form.form-search {
      input.text {
        border-color: #d6d1ca;
        background: #d6d1ca;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1.12px;

        &:-moz-placeholder {
          color: white;
          opacity: 1;
        }
        &::-moz-placeholder {
          color: white;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: white;
        }
        &::-webkit-input-placeholder {
          color: white;
        }
      }
    }
  }
}

form.form-search-page {
  @include rem(margin-bottom, 40px);
  @include rem(padding, 20px);
  background: #f9f8f6;

  .cell {
    display: flex;
    margin-bottom: 0;

    .btn-set {
      @include rem(padding-left, 10px);
      @include rem(flex, 0 0 80px);
      position: relative;
    }

    .loader {
      display: none;
    }

    &.loading .loader {
      position: absolute;
      @include rem(left, -60px);
      top: 0;
      @include rem(width, 60px);
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      text-align: center;
      @include rem(line-height, 60px);
      background: none;
      border: none;
    }
  }
}

/* Form contact */

form.form-contact {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  label {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: #97928e;
    text-transform: uppercase;
  }

  .cell {
    padding-left: auto;
  }

  .btn-set {
    text-align: center;
  }
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

/* Form subscribe */

form.form-subscribe {
  .input {
    display: flex;
  }

  .btn {
    margin-left: 10px;
  }
}

/************************************************************************************
FLASH MESSAGES
*************************************************************************************/

.flash-message {
  font: normal 16px/24px Roboto, sans-serif;
  background: #fc796b;
  color: #fff;
  width: 100%;
  @include rem(padding, 15px 50px 15px 20px);
  @include rem(margin, 0 0 20px 0);
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 0 0 4px 4px;

  .close {
    font: normal 36px/24px Roboto, sans-serif;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 18px;
    text-align: center;
    color: #c96055;
    border: none;
    display: block;
    position: absolute;
    right: 17px;
    top: 16px;
    background: none;

    &:hover {
      color: #fff !important;
    }
  }
}

.flash-message.alert-danger {
  background: #fae7ea;
  color: #e5547a;

  .close {
    color: #e5547a;
  }
}

.flash-message.alert-success {
  background: #ebf5bc;
  color: #98b028;

  .close {
    color: #98b028;
  }
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
  position: relative;

  &.alt {
    .tab-nav {
      li {
        a.active {
          background: #f9f8f6;
        }
      }
    }
  }
}

.tab-nav {
  ul {
    @include rem(margin, 0 -8px);
    padding: 0;
    position: relative;
    top: 2px;
    display: flex;
    justify-content: center;
  }

  li {
    list-style-type: none;
    @include rem(padding, 0 8px);
    background: none;
    margin: 0;
    //width: 50%;

    a {
      font-family: $fontFamily;
      @include rem(font-size, 20px);
      line-height: 24px;
      font-weight: 600;
      text-decoration: none;
      outline: 0;
      @include rem(padding, 15px 30px);
      color: $golden1;
      border: 2px solid #dedbd7;
      border-bottom: none;
      text-align: center;
      border-radius: 10px 10px 0 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .txt {
        position: relative;
        @include rem(padding-left, 57px);
        display: inline-block;
        vertical-align: top;

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          @include rem(width, 40px);
          @include rem(height, 40px);
          top: 50%;
          @include translate(0, -50%);
        }

        .icon-czech {
          top: -8px;
        }
      }

      &:hover {
        background: #f9f8f6;
      }

      &.active {
        background: #fff;
        background: #efeeec;
        border-color: #bcb6af;
        //border-bottom: 2px solid transparent;
      }
    }
  }
}

.tab-content {
  border-top: 2px solid #bcb6af;
}

/************************************************************************************
QTIP
*************************************************************************************/

.qtip-default {
  font: normal 14px/22px Roboto, sans-serif;
  padding: 5px;
  background-color: #cdaf65;
  color: #cdaf65;
  border: none;
}

.qtip-content {
  font-size: 13px;
  color: #fff;
}

/************************************************************************************
FANCYBOX
*************************************************************************************/

body .fancybox-thumbs {
  top: auto;
  width: auto;
  bottom: 10px;
  left: 0;
  right: 0;
  height: 80px;
  background: transparent;
  text-align: center;

  .fancybox-inner {
    right: 0;
  }

  & > ul {
    width: 100% !important;

    & > li {
      float: none;
      display: inline-block;
      vertical-align: top;
    }
  }
}

body .fancybox-slide {
  padding: 20px;

  & > * {
    overflow: visible;
  }
}

body .fancybox-close-small {
  background: #000;
  border-radius: 50%;
  right: -20px;
  top: -20px;

  &:hover {
    background: #fff;

    &:after {
      background: none;
      color: #000;
    }
  }
}

body.compensate-for-scrollbar {
  margin: 0 !important;
}

/************************************************************************************
DATEPICKER
*************************************************************************************/

body .ui-datepicker {
  &.ui-widget {
    font-size: 0.9em;
    width: 20em;
  }

  &.ui-widget-content {
    background: #fff;
  }

  td span,
  td a {
    padding: 0.5em;
    line-height: 1.5em;
  }

  .ui-widget-header {
    background: #e65828;
    color: #fff;
    border: none;
  }

  .ui-datepicker-header {
    padding: 0.5em 0;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    top: 6px;
    cursor: pointer;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: #e5f2ff;
    border: none;
  }

  .ui-datepicker-prev span {
    position: absolute;
    left: 10px;
    top: 50%;
    margin: -5px 0 0 0;
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-left: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    transform: rotate(45deg);
    display: block;
  }

  .ui-datepicker-next span {
    position: absolute;
    left: auto;
    right: 10px;
    top: 50%;
    margin: -5px 0 0 0;
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-right: 0.2em solid #fff;
    border-top: 0.2em solid #fff;
    transform: rotate(45deg);
    display: block;
  }

  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover,
  .ui-state-focus,
  .ui-widget-content .ui-state-focus,
  .ui-widget-header .ui-state-focus {
    border: none;
    background: #e65828;
    color: #fff;
  }
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal-box {
  display: none;
  background: #eee;
}

.modal-content {
  @include rem(padding, 40px);
}

/* Modal contact */

.modal-contact {
  max-width: 600px;
}

/* Modal instagram */

.modal-instagram {
  max-width: 935px;
  padding: 0;
  background: #fff;

  .modal-content {
    padding: 0;
  }

  .img {
    @include rem(margin-right, 335px);
  }

  .text {
    font-size: 14px;
    line-height: 1.3em;
    color: #262626;
    @include rem(width, 335px);
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @include rem(padding, 34px 24px 20px 24px);
    }

    .desc {
      display: flex;
      flex: 1 1 auto;
      order: 1;
      align-items: stretch;
      flex-direction: column;
      @include rem(padding-bottom, 20px);

      .link {
        color: #262626;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .info {
      display: flex;
      flex: 0 1 auto;
      order: 2;
      flex-direction: row;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      padding: 5px 0;

      .likes {
        margin-bottom: 5px;
      }

      .date {
        font-size: 10px;
        color: #999;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
  }
}

/* Modal campaign */

.modal-campaign {
  max-width: 650px;
  background: none;

  .modal-content {
    background: #fff;

    p {
      margin: 0;
    }
  }
}

/************************************************************************************
FIXER
*************************************************************************************/

.fixer-start {
  position: relative;
}

.fixer {
  position: relative;
  top: 0;
  max-width: px;
}

.fixer.fixed {
  position: fixed;
}

/************************************************************************************
UNIFORM
*************************************************************************************/

div.checker {
  position: relative;

  span {
    display: inline-block;
    text-align: center;
    background: url('../img/uniform/uniform.svg') -52px -11px no-repeat;
    @include rem(background-position, -52px -11px);
    @include rem(background-size, 80px);
    @include transition(all, 0.3);
  }

  span.checked {
    //background-position: -52px -51px;
    @include rem(background-position, -52px -51px);
  }

  input {
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: inline-block;
    vertical-align: top;
  }

  &.active span {
    //background-position: -52px -51px;
    @include rem(background-position, -52px -51px);

    &.checked {
      //background-position: -52px -11px;
      @include rem(background-position, -52px -11px);
    }
  }

  &.hover span,
  &.focus span {
    //background-position: -52px -11px;
    @include rem(background-position, -52px -11px);

    &.checked {
      //background-position: -52px -51px;
      @include rem(background-position, -52px -51px);
    }
  }

  &.hover.active span,
  &.focus.active span {
    //background-position: -52px -11px;
    @include rem(background-position, -52px -11px);

    &.checked {
      //background-position: -52px -51px;
      @include rem(background-position, -52px -51px);
    }
  }
}

div.checker,
div.checker span,
div.checker input {
  @include rem(width, 20px);
  @include rem(height, 20px);
}

/* Radio */

div.radio {
  position: relative;

  span {
    display: inline-block;
    text-align: center;
    background: url('../img/uniform/uniform.svg') -12px -11px no-repeat;
    @include transition(all, 0.3);

    &.checked {
      //background-position: -12px -51px;
      @include rem(background-position, -12px -51px);
    }
  }

  input {
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    margin: 0;
    border: none;
    background: none;
    display: inline-block;
    text-align: center;
  }

  &.active span {
    //background-position: -12px -51px;
    @include rem(background-position, -12px -51px);

    &.checked {
      //background-position: -12px -11px;
      @include rem(background-position, -12px -11px);
    }
  }

  &.hover span,
  &.focus span {
    //background-position: -12px -51px;
    @include rem(background-position, -12px -51px);

    &.checked {
      //background-position: -12px -51px;
      @include rem(background-position, -12px -51px);
    }
  }

  &.hover.active span,
  &.focus.active span {
    //background-position: -12px -51px;
    @include rem(background-position, -12px -51px);

    &.checked {
      //background-position: -12px -51px;
      @include rem(background-position, -12px -51px);
    }
  }
}

div.radio,
div.radio span,
div.radio input {
  @include rem(width, 24px);
  @include rem(height, 24px);
  cursor: pointer;
}

/************************************************************************************
SUDO SLIDERS
*************************************************************************************/

.slider {
  overflow: hidden;
  background: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: block;
  }

  li.panel {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: block;
    overflow: hidden;
    background: none;
  }
}

.slider-nav {
  position: static;
  text-align: center;

  ol {
    @include rem(margin, 0 -4px);
  }

  li {
    @include rem(padding, 0 4px);
    background: none;
    display: inline-block;
    font-size: 0;

    &:before {
      content: '';
    }

    a {
      @include rem(width, 10px);
      @include rem(height, 10px);
      display: block;
      background: rgba(255, 255, 255, 0.5);
      text-indent: -80000px;
      border-radius: 50%;

      &:hover {
        background: #fff;
      }

      &.current {
        background: none;
        border: 3px solid #fff;
        @include rem(border-width, 3px);
      }
    }
  }

  .prev {
    position: absolute;
    left: 0;
    top: 50%;
    @include rem(width, 40px);
    @include rem(height, 40px);
    @include rem(margin-top, -20px);
    display: flex;
    //background: #ffc20e;
    //border: 2px solid #dedbd7;
    //border-radius: 50%;

    &:hover {
      color: #cbaf65;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  .next {
    position: absolute;
    right: 0;
    top: 50%;
    @include rem(width, 40px);
    @include rem(height, 40px);
    @include rem(margin-top, -20px);
    display: flex;
    //background: #ffc20e;
    //border: 2px solid #dedbd7;
    //border-radius: 50%;

    &:hover {
      color: #cbaf65;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }
}

/* Teaser slider */

.teaser-slider {
  position: relative;

  .slider {
    height: auto !important;

    li.panel {
      margin: 0;
      display: none;
      background-size: cover !important;
      background-position: center center !important;

      &.shade:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
      }

      &:first-child {
        display: block;
      }
    }
  }
}

.teaser-detail {
  .teaser-content {
    height: auto;
  }

  .slider-nav {
    bottom: 50px;
  }

  &:after {
    height: 100px; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,.65+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
  }
}

.teaser {
  .slider-nav {
    position: absolute;
    left: 50%;
    @include rem(bottom, 30px);
    z-index: 100;
    @include translate(-50%, 0);

    ol {
      display: flex;
    }
  }
}

/* Testimonial slider */

.testimonial-slider {
  position: relative;
  @include rem(padding, 0 60px);

  .slider-nav {
    ol {
      li {
        a {
          background: #7a6e60;
        }

        &.current {
          a {
            background: #cbaf65;
          }
        }
      }
    }
  }
}

/************************************************************************************
TRANSITIONS & ANIMATIONS
*************************************************************************************/

a {
  transition: color 0.1s;
  -moz-transition: color 0.1s;
  -webkit-transition: color 0.1s;
  -o-transition: color 0.1s;
  -ms-transition: color 0.1s;
}

.tag-list ul li a {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}
