﻿/************************************************************************************
HEADER
*************************************************************************************/

.header {
	padding: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 100;
	width: 100%;

	.container {
		width: 100%;
		max-width: 1540px;
	}

	.inner {
		width: 100%;
		max-width: 1600px;
		margin: 0 auto;
		position: relative;
		@include rem(height, 100px);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		display: block;
		@include rem(max-width, 170px);
		@include rem(padding-right, 15px);
	}

	.search {
		width: 100%;
		//flex: 0 0 100%;
		@include rem(max-width, 170px);
		@include rem(height, 50px);
	}

	.header-r {
		display: flex;
		align-items: center;
	}

	a {
		letter-spacing: 1.12px;
	}

	.social-nav ul li a {
		background: #ffffff55;
		border-color: #ffffff55;
		border: 0;
		color: white;
		@include rem(width, 49px);
		@include rem(height, 49px);
		transition: all 0.2s;
		.icon {
			fill: white;
			width: 20px;
			height: 20px;
		}

		&:hover {
			background: #ffffff88;
			color: #fff;
		}
	}	

	&.alt {
		position: relative;
		max-width: 1600px;
		background: #fff;
		margin: 0 auto;
		border-bottom: 1px solid #eaeaea;

		.inner {
			background: #fff;
		}

		a {
			color: #555351;
		}

		a:hover {
			color: #4c4740;
		}

		form.form-search .btn {
			color: white;
		}

		.main-nav ul li a:after {
			background: #4c4740;
		}

		.main-nav ul li.menu-active a:after {
			background: #4c4740;
		}

		.social-nav ul li a {
			background: #d6d1ca;
			border-color: #d6d1ca;
			color: #7a6e60;
			&:hover {
				background: #7a6e60;
				color: #fff;
				opacity: 1;
			}
		}	

		.nav-btn {
			.line-1,
		  .line-2,
		  .line-3 {		   
		    background: #4c4740;
		  }
		}
	}
}

.social-nav {
	@include rem(margin-right, 10px);

	ul {
		@include rem(margin, 0 -5px);
		display: flex;

		li {
			margin: 0;
			@include rem(padding, 0 5px);

			a {
				@include rem(width, 30px);
				@include rem(height, 30px);
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				border-radius: 50%;
				background: #ADA295;
				&:hover {
					background: #fff;
					color: #7a6e60;
				}

				.icon {
					@include rem(width, 15px);
					@include rem(height, 15px);
				}	
			}
		}			
	}
}

.teaser {
	margin: 0;
	position: relative;
	z-index: 50;
}

.teaser-content {
	height: 757px;
	min-height: 500px;
	max-height: 100vh;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	h1 {
		font-weight: 600;
		color: #fff;
		margin-bottom: 0;
	}
	
	.medium {
		width: 100%;
		max-width: 660px;
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 50;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.desc {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		@include rem(padding, 0 15px 90px 15px);
		text-align: right;
		z-index: 50;

		a {
			@include rem(font-size, 16px);
			font-weight: 600;
			color: #fff;
			text-transform: uppercase;
			text-decoration: underline;
			position: relative;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.main-nav {
	@include rem(margin, 0 10px);
	text-align: center;

	ul {
		@include rem(margin, 0 -12px);
		display: flex;

		li {
			@include rem(padding, 0 12px);
			margin: 0;
			background: none;

			a {
				@include rem(font-size, 14px);
				font-weight: 600;
				color: #dcdcdc;
				white-space: nowrap;
				text-transform: uppercase;
				text-decoration: none;
				position: relative;

				&:after {
					content: '';
					width: 0;
					height: 2px;
					position: absolute;
					left: 50%;
					bottom: -15px;
					background: #fff;
					@include translate(-50%,0);
					@include transition(all, .3);
				}

				&:hover {
					color: #fff;
				}
				
				&:hover:after {
					width: 100%;
				}
			}

			&.menu-active a:after {
				width: 100%;
			}
		}
	}
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  @include rem(margin-left, 15px);
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;

  .lines {
    width: 30px;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  &.active {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        //background: #fff;
      }
    }
  }
}

.mobile-nav {
	background: #4c4740;
	//height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 95;
	overflow-y: auto;
	width: 100%;
	//max-width: 320px;
	padding: 120px 0 40px 0;
	@include transition(all, .3);
	@include translate(0, -100%);
	margin-top: -5px;

	&.active {
		@include translate(0, 0);
		margin-top: 0;
	}

	.close {
		font-size: 30px;
		font-weight: 400;
		width: 30px;
		height: 30px;
		position: absolute;
		right: 15px;
		top: 15px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		display: none !important;

		.icon {
			width: 15px;
			height: 15px;
		}

		&:hover {
			color: #fff;
		}
	}

	.nav {
		ul {
			@include rem(margin, 0 0 30px 0);
			padding: 0;

			li {
				color: #fff;
				margin: 0;
				padding: 0;
				background: none;
				border: none;

				&.active a {
					color: #ffa70e;
				}

				&.last {
					border: none;
				}

				a {
					font-size: 16px;
					font-weight: 400;
					display: block;
					width: 100%;
					padding: 10px 0;
					border-bottom: 1px solid rgba(255,255,255,.2);
					color: #fff;
					position: relative;
					text-decoration: none;
				}
			}
		}
	}
}

.mobile-nav-overlay {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 90;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.8);
	display: none;
}