﻿
/************************************************************************************
CONTENT
*************************************************************************************/

.sights {
	margin: 0 auto;

	.item-list {
		@include rem(margin, 0 -10px -30px -10px);
		position: relative;
		display: flex;
		flex-wrap: wrap;

		&.loading {
			&:after {
				content: '';
				position: fixed;
				left: 50%;
				top: 50%;
				width: 50px;
				height: 50px;
				z-index: 100;
				background: url('../img/spinner.svg') cnter center no-repeat;
				@include translate(-50%,-50%);
			}

			.item {
				opacity: .5;
			}
		}
	}

	.item {
		@include rem(margin, 0 0 30px 0);
		@include rem(padding, 0 10px);
		display: flex;
		@include transition(all, .3);

		.img {
			display: block;
			overflow: hidden;
			text-align: center;
			position: relative;

			img {
				@include transition(all, .3);
				@include scale(1);
				width: 100%;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: 20;
				width: 100%;
				height: 100%;
				background: rgba(63,61,59,.5);
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -1px;
				z-index: 30;
				width: 100%;
				height: 17px;
				background: url('../img/sight-overlay.svg') center 0 no-repeat;
			}

			h3 {
				@include rem(font-size, 32px);
				line-height: 1.3em;
				font-weight: 400;
				@include rem(margin, -17px 0 0 0);
				color: #fff;
				position: absolute;
				left: 0;
				top: 50%;
				z-index: 40;
				width: 100%;
				padding: 10px;
				@include translate(0,-50%);

				&:after {
					content: '';
					background: #fff;
					position: absolute;
					left: 50%;
					@include rem(bottom, -30px);
					@include rem(width, 60px);
					height: 2px;
					@include rem(margin-left, -30px);
				}
			}
		}

		&:not(.disabled) {
			.img:hover {
				&:before {
					background: rgba(63,61,59,.3);
				}

				img {
					@include scale(1.1);
				}
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			background: #fff;
			color: #7d7b79;
			@include rem(padding, 35px 30px 25px 30px);
			text-align: center;

			.desc {
				flex-grow: 1;
				justify-content: space-between;
				@include rem(padding-bottom, 5px);
			}

			.no-detail {
				max-width: 200px;
				min-height: 78px;
				@include rem(padding-top, 10px);
				margin: 0 auto;
				background: url('../img/closed.svg') center center no-repeat;
			}
		}
	}

	.item-inner {
		box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
		display: flex;
		flex-direction: column;
		max-width: 350px;
		margin: 0 auto;
		border-radius: 10px;
		overflow: hidden;
		font-family: $fontFamily;
		@include rem(font-size, 15px);
	}

	.foot {
		text-align: center;
		@include rem(padding-top, 30px);
	}
}

.opening-info {
	font-family: Noto serif,serif;
	@include rem(font-size, 15px);
	font-weight: 600;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	@include rem(margin-bottom, 10px);

	.txt {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;

		.val {
			padding-left: 5px;
			white-space: nowrap;
		}
	}

	.hint {
		display: inline-flex;
		@include rem(width, 20px);
		@include rem(height, 20px);
		margin-left: 10px;

		.icon {
			vertical-align: middle;
		}
	}

	.status {
		@include rem(width, 8px);
		@include rem(height, 8px);
		@include rem(flex, 0 0 8px);
		@include rem(margin-right, 8px);
		display: inline-flex;
		position: relative;
		top: 0;
		border-radius: 50%;

		&.open {
			background: #70ad3a;
		}

		&.closed {
			background: #e91144;
		}
	}
}

.cat-list {
	margin: 0;

	ul {
		@include rem(margin, 0 0 -10px 0);
		text-align: center;

		li {
			color: #aa9e90;
			display: inline-block;
			vertical-align: top;
			@include rem(margin, 0 0 10px 0);
			@include rem(padding-left, 5px);
			@include rem(padding-right, 5px);
			padding-right: 5px;

			a {
				color: #aa9e90;
				display: block;

				.js-qtip {
					display: block;
					cursor: help;
				}
			}

			a:hover {
				color: #4c4740;
			}

			.icon {
				@include rem(width, 40px);
				@include rem(height, 40px);
			}

			&:before {
				display: none;
			}
		}
	}
}

.posts {
	margin: 0 auto;

	.intro {
		@include rem(font-size, 18px);
		line-height: 1.625em;
		@include rem(margin-bottom, 80px);
	}

	.item-inner {
		display: flex;
	}

	.item {
		@include rem(margin-bottom, 60px);

		.date {
			@include rem(font-size, 14px);
			font-weight: 600;
			color: #97928e;
			text-transform: uppercase;
			@include rem(margin, 10px 0);
		}

		.img {
			flex: 0 0 50%;
			max-width: 50%;
			float: left;
			display: block;
		}

		.text {
			flex: 0 0 50%;
			max-width: 50%;

			.inner {
				@include rem(padding-left, 20px);
			}
		}

		&:nth-child(4n),
		&:nth-child(4n+3) {
			.img {
				order: 1;
			}

			.text {
				order: 0;

				.inner {
					padding-left: 0;
					@include rem(padding-right, 20px);
				}
			}
		}
	}

	.foot {
		text-align: center;

		.btn:hover {
			background: #fff;
			color: #7a6e60;
		}
	}

	&.tips {
		.foot {
			.btn:hover {
				background: #cbaf65;
				color: #fff;
			}
		}
	}
}

.tag-list {
	ul {
		@include rem(margin, 0 -5px);
		display: flex;
		flex-wrap: wrap;

		li {
			@include rem(padding, 0 5px);
			@include rem(margin, 0 0 5px 0);

			a {
				@include rem(font-size, 12px);
				@include rem(line-height, 18px);
				font-weight: 500;
				display: inline-flex;
				align-items: center;
				text-transform: uppercase;
				color: #97928e;
				border: 2px solid #97928e;
				@include rem(padding, 2px 8px);
				letter-spacing: .05em;
				text-decoration: none;
				border-radius: 4px;
				cursor: default;
			}

			/*a:hover {
				border-color: #4c4740;
				color: #4c4740;
				background: #4c4740;
				color: #fff;
			}*/
		}
	}

	&.center {
		ul {
			justify-content: center;
		}
	}
}

.block {
	&.alt-2 {
		.posts {
			.item {
				h3 {
					color: #fff;

					a {
						color: #fff;
					}
				}
			}
		}

		.tag-list {
			ul {
				li {
					a:hover {
						background: #97928e;
						border-color: #97928e;
						color: #fff;
					}
				}
			}
		}
	}
}

.post-detail {
	max-width: 800px;
	margin: 0 auto;
}

.tips{
	.filter {
		@include rem(padding, 40px);
		background: #f9f8f6;
	}

	.intro {
		@include rem(margin-bottom, 40px);
	}
}

.tips-related {
	.section-head {
		@include rem(margin-bottom, 40px);
	}

	.item-list {
		@include rem(margin-bottom, -60px);
	}
}

.tips-side {
	.item {
		@include rem(margin-bottom, 30px);
		display: flex;

		.item-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.text {
			@include rem(padding, 15px);
			background: #fff;
			flex-grow: 1;
		}
	}
}

.gallery {
	margin: 0 auto;

	.item-list {
		@include rem(margin, 0 -10px);
	}

	.item {
		width: 16.666666%;
		@include rem(padding, 0 10px);
		@include rem(margin-bottom, 20px);

		a {
			display: block;
			position: relative;
			overflow: hidden;

			img {
				@include transition(all, .3);
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0);
				@include transition(all, .3);
			}

			&:hover img {
				@include scale(1.1);
			}

			&:hover:after {
				background: rgba(0,0,0,.2);
			}
		}
	}
}

.instagram {
	.item-list {
		margin-left: -1px;
		margin-right: -1px;
	}

	.item {
		padding-left: 1px;
		padding-right: 1px;
		margin-bottom: 2px;

		.img {
			position: relative;
			display: block;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: rgba(0,0,0,0);
				@include transition(all, .3);
			}

			&:hover:after {
				background: rgba(0,0,0,.3);
			}

			&:hover .info {
				top: 0;
				opacity: 1;
			}

			.info {
				position: absolute;
				z-index: 30;
				left: 0;
				top: -100%;
				width: 100%;
				height: 100%;
				pointer-events: none;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				@include transition(all, .3);

				a {
					text-decoration: none; color: #fff;
				}

				ul {
					margin: 0;
					display: flex;

					li {
						padding: 0 10px;
						margin: 0; display: flex;
						color: #fff;
					}
				}
			}
		}
	}
}

.detail-info {
	margin: 0 auto;

	h2 {
		@include rem(margin-bottom, 30px);
		color: #7d7b79;
	}

	.section-head h2 {
		margin: 0;
		font-weight: 600;
		color: #7d7b79;
	}

	.intro-head {
		text-align: center;
		@include rem(margin-bottom, 40px);

		h2 {
			@include rem(margin, 0 0 20px 0);
			font-weight: 600;
			color: #7d7b79;
		}
	}

	.contact-info {
		@include rem(margin-bottom, 40px);

		a {
			color: #7d7b79;

			&:hover {
				color: #645d52;
			}
		}

		.contact-list {
			li {
				&:before {
					content: none;
				}

				&.route {
					//@include rem(padding-top, 10px);
					padding-top: 0;
					font-weight: 600;

					a {
						color: #cdaf65;
						text-decoration: underline;
					}

					a:hover {
						text-decoration: none;
					}

					.icon {
						//@include rem(top, 12px);
					}
				}
			}
		}

		.smap-wrapper {
			height: 300px;
			@include rem(margin-bottom, 30px);
		}
	}
}

.opening {
	@include rem(margin-bottom, 40px);
	max-width: 460px;

	table {
		width: 100%;

		th {
			border-bottom: 0 solid #eaeaea;
			text-align: left;
		}

		td {
			border-bottom: 1px solid #eaeaea;
			@include rem(padding, 8px 20px);
			vertical-align: middle;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0; text-align: right;
			}

			&.month,
			&.head {
				font-weight: 600;
			}

			&.hour {
				text-align: center;
			}

			&.action {
				@include rem(width, 60px);
			}
		}

		p {
			margin-bottom: 0;
		}

		.schedule-hint {
			@include rem(width, 20px);
			@include rem(height, 20px);
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.schedule-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include rem(margin-bottom, 10px);
	@include rem(padding-bottom, 10px);
	border-bottom: 2px solid #eaeaea;

	.nav-prev {
		order: 1;
		@include rem(width, 30px);
	}

	.nav-next {
		order: 3;
		@include rem(width, 30px);
	}

	.heading {
		order: 2;
		font-family: Noto serif,serif;
		@include rem(font-size, 18px);
		font-weight: 600;
		line-height: 1.3em;
		@include rem(padding, 0 10px);
		color: #cdaf65;
	}

	.link {
		@include rem(width, 30px);
		@include rem(height, 30px);
		display: block;
		background: #cdaf65;
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		.icon {
			@include rem(width, 30px);
			@include rem(height, 30px);
			color: #fff;
			display: none !important;
		}

		&:hover {
			color: #fff;
			background: #7a6e60;
		}

		&.hidden {
			display: none;
		}
	}

	.nav-prev .link:before {
		content: '';
		position: absolute;
		left: 12px;
		top: 50%;
		margin-top: -5px;
		display: inline-block;
		width: .6em;
		height: .6em;
		border-left: .15em solid #fff;
		border-bottom: .15em solid #fff;
		transform: rotate(45deg);
		display: block;
	}

	.nav-next .link:before {
		content: '';
		position: absolute;
		left: 8px;
		top: 50%;
		margin-top: -5px;
		display: inline-block;
		width: .6em;
		height: .6em;
		border-right: .15em solid #fff;
		border-top: .15em solid #fff;
		transform: rotate(45deg); display: block;
	}
}

.events {
	margin: 0;

	.item-list {
		margin: 0;
	}

	.item-inner {
		border: 1px solid #eaeaea;
		@include rem(padding, 45px 35px);
	}

	.item {
		width: 100%;

		.img {
			float: left;
		}

		.text {
			@include rem(padding-left, 370px);

			h3 {
				margin-bottom: 10px;
				color: #7d7b79;
			}

			.inner {
				@include rem(padding, 0 0 0 35px);
				border-left: none;
				position: relative;
			}

			.date {
				@include rem(font-size, 16px);
				font-weight: 600;
				color: #7d7b79;
				@include rem(margin-bottom, 10px);
			}

			.actions {
				@include rem(margin-top, 30px);

				ul {
					margin: 0;
					display: table-row;
					width: 100%;

					li {
						display: table-cell;
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}

.event-archive {
	margin: 0;

	.item-inner {
		padding: 0 0 30px 0;
		display: flex;
		border-bottom: 2px solid #eaeaea;
		width: 100%;
	}

	.item {
		@include rem(margin-bottom, 30px);
		display: flex;
		background: white;
		border-radius: 10px;
		.tag-list {
			@include rem(margin, 0 10px 0 0);

			li {
				margin: 2px 0;
			}
		}

		.img {
			@include rem(flex, 0 0 180px);
			@include rem(max-width, 180px);
		}

		.text {
			@include rem(padding-left, 30px);
			flex-grow: 1;
		}

		.meta {
			display: flex;
			flex-wrap: wrap;
		}

		.desc {
			//@include rem(max-height, 78px);
			//max-height: 78px;
			overflow: hidden;
			//text-overflow: ellipsis;
			//@include rem(margin-bottom, 20px);
		}

		.date {
			@include rem(font-size, 14px);
			font-weight: 600;
			color: #97928e;
			text-transform: uppercase;
			margin-bottom: 0;
			display: flex;
			align-items: center;

			.icon {
				@include rem(width, 18px);
				@include rem(height, 18px);
				@include rem(flex, 0 0 18px);
				@include rem(margin-right, 10px);
			}
		}

		.object-link {
			display: flex;
			align-items: center;
			@include rem(margin, 10px 0);

			.icon {
				margin-right: 5px;
			}
		}

		h3 {
			color: #7a6e60;
		}
	}
}

.date-switcher {
	@include rem(margin, 0 0 60px 0);
	position: relative;

	&.bottom {
		@include rem(margin-top, 40px);
	}

	.inner {
		display: flex;
		justify-content: center;
		width: 100%;
		position: relative;
	}

	.head {
		padding: 0 40px;

		h2 {
			margin: 0;
		}
	}

	.nav-prev {
		position: absolute;
		left: 0;
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		justify-content: center;
		align-items: center;

		.txt {
			order: 1;
		}

		.icon {
			order: 0;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #cdaf65;
			margin-right: 10px;

			&:before {
				content: '';
				position: absolute;
				left: 12px;
				top: 50%;
				margin-top: -5px;
				display: inline-block;
				width: .6em;
				height: .6em;
				border-left: .15em solid #fff;
				border-bottom: .15em solid #fff;
				transform: rotate(45deg);
				display: block;
			}
		}
	}

	.nav-next {
		position: absolute;
		right: 0;
		top: 50%;
		@include translate(0,-50%);
		display: flex;
		justify-content: center;
		align-items: center;

		.txt {
			order: 0;
		}

		.icon {
			order: 1;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #cdaf65;
			margin-left: 10px;

			&:before {
				content: '';
				position: absolute;
				left: 8px;
				top: 50%;
				margin-top: -5px;
				display: inline-block;
				width: .6em;
				height: .6em;
				border-right: .15em solid #fff;
				border-top: .15em solid #fff;
				transform: rotate(45deg); display: block;
			}
		}
	}
}

.main-cats {
	margin: 0 auto;
	text-align: center;
	max-width: 500px;

	.item-list {
		@include rem(margin-bottom, -20px);
	}

	.item {
		border-left: 1px dashed #e0dedc;
		@include rem(margin-bottom, 20px);
		display: flex;

		&:first-child {
			border: none;
			justify-content: flex-end;
		}

		&:first-child .item-inner {
			@include rem(padding-right, 20px);
		}

		.text {
			font-family: Noto serif,serif;
			@include rem(font-size, 14px);
			@include rem(padding-left, 85px);
			max-width: 205px;
			width: 100%;
			text-align: left;
		}

		.img {
			@include rem(width, 85px);
			position: absolute;
			left: 0;
			top: 0;
			color: #ada295;

			.icon {
				display: block;
				margin: 0 auto;
				@include rem(width, 55px);
				@include rem(height, 55px);
			}
		}
	}

	.item-inner {
		position: relative;
		@include rem(min-height, 55px);
		display: flex;
		align-items: center ;
	}
}

.properties {
	display: table;
	width: 100%;

	.item-list {
		display: flex;
		justify-content: center;
	}

	.item {
		@include rem(font-size, 18px);
		font-family: Noto serif,serif;
		line-height: 1.3em;
		font-weight: 400;
		text-align: center;
		@include rem(padding, 30px 15px);

		.head {
			@include rem(font-size, 14px);
			max-width: 120px;
			display: block;
			margin: 0 auto;
		}

		.icon {
			@include rem(width, 45px);
			@include rem(height, 45px);
			color: #aa9e90;
			display: block;
			margin: 0 auto;
		}
	}
}

.about {
	margin: 0;
}

.text-image {
	@include rem(margin-bottom, 120px);
	display: flex;

	.text {
		order: 0;
		width: 50%;
		padding: 0 15px;
		display: flex;
		align-items: center;
	}

	.img {
		order: 1;
		width: 50%;
		padding: 0 15px;
		@include rem(margin, 15px 0);
		display: flex;
		align-items: center;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	&:nth-child(even) {
		.text {
			order: 1;
		}

		.img {
			order: 0;
		}
	}
}

.partner {
	.img {
		 img {
			display: block;
			margin: 0 auto;
		}
	}
}

.testimonials {
	max-width: 850px;
	margin: 0 auto;

	.item {
		@include rem(font-size, 18px);
		line-height: 1.8em;
		text-align: center;
		font-style: italic;

		.name {
			font-family: Noto serif,serif;
			font-style: normal;
			color: #4c4740;
			font-weight: 600;
		}
	}
}

.fb-widget {
	//max-width: 290px;
	max-width: 500px;
	@include rem(margin, 0 auto 30px auto);
}

.subscribe {
	@include rem(padding, 30px 0);
	p.h4 {
		color: #555351;
	}
	.desc {
		@include rem(max-width, 350px);
		margin-bottom: 0;
	}

	.head {
		display: flex;
		.img {
			@include rem(padding-right, 20px);
		}
	}

	input[type=email].form-control {
		box-shadow: none;
		background: #F0EEE9;
	}
}

.subscribe-popup {
	width: 100%;
	max-width: 850px;
	position: fixed;
	bottom: 0;
	left: 50%;
	@include translate(-50%,100%);
	z-index: 50;
	margin-top: 10px;
	@include transition(all, .5);

	&.active {
		margin-top: 0;
		@include translate(-50%,0);
	}

	.subscribe {
		padding-left: 30px;
		padding-right: 30px;
		background: #fff;
		border: none;
		box-shadow: 0 0 15px 0 rgba(0,0,0,.2);
	}

	.close {
		@include rem(font-size, 20px);
		position: absolute;
		right: 0;
		top: 0;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #7a6e60;
		color: #fff;
		text-decoration: none;

		&:hover {
			background: #cbaf65;
		}
	}
}

.subscribe-trigger {
	position: fixed;
	z-index: 50;
	right: 30px;
	bottom: 30px;
	width: 60px;
	height: 60px;
	background: #fff;
	display: none;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0 0 15px 0 rgba(0,0,0,.2);
	padding: 5px;
	@include transition(all, .3);

	&:hover {
		box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
	}

	&.active {
		display: flex;
	}
}

.search-results {
	max-width: 800px;
	@include rem(margin, 0 auto 60px auto);

	h2 {
		font-family: Noto serif,serif;
		@include rem(font-size, 26px);
		font-weight: 600;
		line-height: 1.3em;
		@include rem(margin-bottom, 30px);
	}

	.item-list {
		@include rem(margin-bottom, 40px);
	}

	.item-inner {
		display: flex;
	}

	.item {
		width: 100%;
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid #eaeaea;

		.img {
			width: 100%;
			max-width: 150px;
			flex: 0 0 150px;
		}

		.text {
			@include rem(padding-left, 20px);

			h3 {
				@include rem(font-size, 20px);
			}
		}

		.text p:last-child {
			margin-bottom: 0;
		}

		&.no-image .text {
			padding-left: 0;
		}
	}

	.foot {
		text-align: center;
	}
}

.search-expression {
	font-weight: 600;
	padding: 15px 0 0;
	color: #cbaf65;
}

body .easy-autocomplete {
	display: flex;
	width: auto !important;
	flex-grow: 1;
	flex-wrap: wrap;
	position: static !important;

	input.text {
		flex: 0 0 100%;
	}
}

body .easy-autocomplete-container {
	@include rem(top, 80px);

	&.active ul {
		display: flex !important;
	}

	ul {
		flex-wrap: wrap;
		margin: 0;
		@include rem(padding, 15px);
		border: none;
		border-top: 3px solid #cbaf65;
		box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);

		&:before {
			content: '';
			position: absolute;
			left: 50%;
			@include rem(top, -15px);
			@include rem(margin-left, -15px);
			width: 0;
			height: 0;
			border-style: solid;
			@include rem(border-width, 0 15px 15px 15px);
			border-color: transparent transparent #cbaf65 transparent;
		}

		li {
			display: flex;
			flex: 0 0 50%;
			width: 100%;
			max-width: 50%;
			@include rem(margin, 0 0 20px 0);
			padding: 0;
			border: none;

			&.selected {
				background: none;
			}
		}
	}

	.eac-category {
		font-family: Noto serif,serif;
		@include rem(font-size, 18px);
		font-weight: 600;
		line-height: 1.3em;
		font-style: normal !important;
		flex: 0 0 100%;
		padding: 20px 0;
		color: #cbaf65;
		border: none !important;

		&:first-of-type {
			padding-top: 0;
		}
	}

	.eac-item {
		display: flex;
		padding: 0 15px 0 0;
		width: 100%;

		.img {
			flex: 0 0 60px;
			max-width: 60px;
			max-height: inherit !important;
			height: auto;
			margin-right: 10px;

			img {
				max-width: 100%;
				max-height: inherit !important;
			}
		}
	}
}

body .header .easy-autocomplete {
	display: block;
}

body .header .easy-autocomplete-container {
	top: 130px;

	ul:before {
		left: auto;
		right: 70px;
	}
}

.svg-map {
	max-width: 550px;
	@include rem(margin, 0 auto 20px auto);
	position: relative;

	svg {
		pointer-events: auto;
		max-width: 100%;
		height: auto;
	}

	.base {
		display: block;
	}

	.area {
		fill: #fff;
		cursor: pointer;
		transition: all 0.3s;

		&:hover {
			fill: #e5e1d9;
		}

		&.active {
			fill: #efece7;
		}
	}

	.labels {
		.item {
			@include rem(font-size, 11px);
			font-family: Noto serif,serif;
			line-height: 1.3em;
			@incude rem(max-width, 90px);
			pointer-events: none;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%,-50%);

			.num {
				font-weight: 600;
			}

			&.jihomoravsky-kraj {
				left: 68%;
				top: 78%;
			}

			&.zlinsky-kraj {
				left: 83%;
				top: 74%;
			}

			&.olomoucky-kraj {
				left: 77%;
				top: 57%;
			}

			&.pardubicky-kraj {
				left: 60%;
				top: 46%;
			}

			&.plzensky-kraj {
				left: 15%;
				top: 57%;
			}

			&.jihocesky-kraj {
				left: 34%;
				top: 79%;
			}

			&.moravskoslezsky-kraj {
				left: 89%;
				top: 52%;
			}

			&.kralovehradecky-kraj {
				left: 54%;
				top: 28%;
			}

			&.stredocesky-kraj {
				left: 36%;
				top: 50%;
			}

			&.ustecky-kraj {
				left: 25%;
				top: 22%;
			}

			&.karlovarsky-kraj {
				left: 10%;
				top: 36%;
			}

			&.liberecky-kraj {
				left: 42%;
				top: 14%;
			}

			&.kraj-vysocina {
				left: 53%;
				top: 65%;
			}

			&.praha {
				left: 35%;
				top: 39%;
			}
		}
	}
}

.map-wrapper {
	position: relative;
	background: #e3e3e3;

	.map {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.map-content {
	height: 500px;
	text-align: right;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;

	.box {
		text-align: left;
		position: relative;
		z-index: 20;
		width: 100%;
		padding: 0;
		max-width: 300px;
		display: inline-block;
		vertical-align: top;

		.box-text {
			@include rem(padding, 30px);
			background: #fff;

			ul:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.map {
	width: 100%;
	height: 650px;

	.markers {
		display: none;
	}

	img {
		max-width: inherit;
	}
}

.popup {
	font-size: 15px;
	background: #fff;
	@include rem(width, 300px);

	.close {
		font-size: 20px;
		@include rem(width, 30px);
		@include rem(height, 30px);
		@include rem(line-height, 30px);
		text-align: center;
		background: #cbaf65;
		color: #fff;
		text-decoration: none;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
	}

	.text {
		@ínclude rem(padding, 30px);

		p {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	h3 {
		font-size: 17px;
		@include rem(margin, 0 0 10px 0);
	}

	ul {
		margin: 0;

		li {
			padding: 0; background: none;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
/*
.gm-style .gm-style-iw {
	background-color: #3C61AD !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	max-width: 300px;
	min-height: 120px !important;
	padding: 0;
	display: block !important;
}

.js-info-bubble-close {
	position: absolute;
	right: 0 !important;
	top: 0 !important;
	z-index: 10;
	display: block;
	width: 30px;
	height: 30px;
	opacity: 0;
}
*/
.smap-wrapper {
	height: 650px;
	position: relative;
	@include rem(margin-bottom, 60px);

	.box {
		@include rem(font-size, 15px);
		line-height: 1.6em;
		position: absolute;
		left: 0;
		@include rem(bottom, 20px);
		z-index: 50;
		background: #fff;
		@include rem(padding, 30px);
		min-width: 260px;

		h3 {
			@include rem(font-size, 24px);
			line-height: 1.2em;
			color: #236276;
		}

		ul {
			margin: 0;

			&.contact-list li {
				@include rem(padding-left, 30px);
				margin: 0;
				position: relative;

				.icon {
					position: absolute;
					left: 0;
					@include rem(top, 5px);
					@include rem(width, 20px);
					@include rem(height, 20px);
					color: #3aaa35;
				}
			}
		}
	}
}

.smap {
	width: 100%;
	height: 100% !important;
	position: absolute !important;
	left: 0;
	top: 0;

	img {
		max-width: inherit;
	}
}

body .smap {
	.leaflet-popup-content-wrapper, .leaflet-popup-tip {
		padding: 0;
	}

	.leaflet-popup-content, .card {

		padding: 0;
		background: none;
		box-shadow: none;
		overflow: visible !important;
		//@include translate(-10px, 0);
		margin: 0 !important;

		img {
			max-width: 100%;
		}

		.text {
			@include rem(padding, 20px);

			.title {
				font-size: 19px;
				font-family: Noto serif, serif;
				color: #7a6e60;
				@include rem(margin, 0 0 5px 0);

				a {
					color: #7a6e60;
				}
			}

			.desc {
				@include rem(font-size, 14px);
				line-height: 1.5em;

				p:last-child {
					margin-bottom: 0;
				}
			}

			.cat-list {
				@include rem(padding-top, 10px);
			}
		}

		.tail {
			position: absolute;
			left: 50%;
			margin-left: -10px;
			bottom: -10px;
			width: 0;
			height: 0;
			visibility: visible;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: #fff transparent transparent transparent;
		}

		.opening-info {
			@include rem(margin, 10px 0 0 0);
		}
	}

	.leaflet-popup-content .card-header, .card-header {
		background: #fff;
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .2);
	}

	.close, .leaflet-popup-close-button {
		position: absolute;
		right: -15px;
		top: -15px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #555351;
		border-radius: 50%;

		span {
			display: none;
		}

		&:after {
			font-family: mapy;
			content:"c";
			font-size:20px;
			color: #fff;

		}

		&:hover {
			color: #fff;
			background: #4c4740;

			&:after {
				color: #fff;
			}
		}
	}
}
